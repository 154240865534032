import React, { useEffect, useState } from 'react'
import './style.css'
import { Instagram, Telegram, WhatsApp } from '@mui/icons-material'
import { getAssetUrl } from '../utils'
import { useSelector } from 'react-redux'

const SocialMediaStickyIcons: React.FC<any> = ({
  whatsappLink,
  telegramLink,
  instagramLink,
}) => {
  const [isBouncing, setIsBouncing] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [isTelegramHovering, setIsTelegramHovering] = useState(false)
  const [isInstagramHovering, setIsInstagramHovering] = useState(false)
  const { isDarkMode } = useSelector((state: any) => state.theme)

  useEffect(() => {
    const interval = setInterval(() => {
      setIsBouncing(true)
      setTimeout(() => setIsBouncing(false), 1000)
    }, 10000)

    return () => clearInterval(interval)
  }, [])

  const renderIcon = (
    link: string | undefined,
    IconComponent: any,
    platform: string,
  ) =>
    link && (
      <a
        aria-label={`link ${platform !== 'instagram' && platform}`}
        href={
          link.startsWith('http')
            ? link
            : `https://${platform === 'whatsapp' ? 'wa.me' : platform === 'telegram' ? 't.me' : ''}/${link}`
        }
        target='_blank'
        rel='noopener noreferrer'
        className={`cursor-pointer icon ${platform}`}
      >
        {platform === 'instagram' ? (
          <img src={getAssetUrl('icons/instagram.webp')} />
        ) : (
          <IconComponent style={{ fontSize: '2rem' }} />
        )}
      </a>
    )

  return (
    <div className='social-sticky-icons-container'>
      <div
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={`${isHovering ? `flex ${!isDarkMode ? 'social-sticky-icons-bigger' : 'social-sticky-icons-dark-bigger'}` : ''}`}
      >
        <div
          className={`social-sticky-icons-whatsapp ${isBouncing ? 'bounce' : ''}`}
        >
          {renderIcon(whatsappLink, WhatsApp, 'whatsapp')}
        </div>
        {isHovering && (
          <div className='dark:text-white text-black bold pl-2'>
            Whatsapp <div>24/7</div>
          </div>
        )}
      </div>

      <div
        onMouseEnter={() => setIsTelegramHovering(true)}
        onMouseLeave={() => setIsTelegramHovering(false)}
        className={`${isTelegramHovering ? `flex ${!isDarkMode ? 'social-sticky-icons-bigger' : 'social-sticky-icons-dark-bigger'}` : ''}`}
      >
        <div
          className={`social-sticky-icons-telegram ${isBouncing ? 'bounce' : ''}`}
        >
          {renderIcon(telegramLink, Telegram, 'telegram')}
        </div>
        {isTelegramHovering && (
          <div className='dark:text-white text-black bold pl-2'>
            Telegram <div>24/7</div>
          </div>
        )}
      </div>

      <div
        onMouseEnter={() => setIsInstagramHovering(true)}
        onMouseLeave={() => setIsInstagramHovering(false)}
        className={`${isInstagramHovering ? `flex ${!isDarkMode ? 'social-sticky-icons-bigger' : 'social-sticky-icons-dark-bigger'}` : ''}`}
      >
        <div
          className={`social-sticky-icons-instagram ${isBouncing ? 'bounce' : ''}`}
        >
          {renderIcon(instagramLink, Instagram, 'instagram')}
        </div>
        {isInstagramHovering && (
          <div className='dark:text-white text-black bold pl-2'>
            Instagram <div>24/7</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SocialMediaStickyIcons
