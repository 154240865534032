import { useEffect } from 'react'
import { ICurrentDomain } from './types/interfaces/currentDomain'
import { useSelector } from 'react-redux'
import LiveChat from './component/liveChat/LiveChat'

declare global {
  interface Window {
    fbq: any
  }
}

interface Props {
  googleTag?: string
  currentDomain: ICurrentDomain
  futur9B2cGoogleTag?: string
  allCasinoGoogleTag?: string
  baazigerExchGoogleTag?: string
}

interface FBQ {
  (action: string, ...args: any[]): void
  callMethod?: (...args: any[]) => void
  queue: any[]
  push: (...args: any[]) => void
  loaded?: boolean
  version?: string
}

export const GoogleTagManager: React.FC<Props> = ({
  googleTag,
  currentDomain,
  allCasinoGoogleTag,
  futur9B2cGoogleTag,
  baazigerExchGoogleTag,
}) => {
  const { metaTags } = useSelector((state: any) => state.appThemeConfiguration)
  const { isLogin, userData } = useSelector((state: any) => state.login)

  useEffect(() => {
    if (!metaTags || !metaTags.length) return

    const loadFacebookPixel = (pixel: any) => {
      ;(function (f: any, b: Document, e: string, v: string) {
        if (f.fbq) return

        const n: FBQ = (f.fbq = function () {
          const args = Array.prototype.slice.call(arguments)
          if (n.callMethod) {
            n.callMethod.apply(n, args)
          } else {
            n.queue.push(args)
          }
        } as any)

        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = true
        n.version = '2.0'
        n.queue = []

        const t = b.createElement(e) as HTMLScriptElement
        t.async = true
        t.src = v
        const s = b.getElementsByTagName(e)[0]
        s.parentNode?.insertBefore(t, s)
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
      )

      const fbqIds = pixel
      fbqIds.forEach((id: any) => {
        window.fbq('init', id)
        window.fbq('track', 'PageView')
      })

      fbqIds.forEach((id: any) => {
        const noscript = document.createElement('noscript')
        noscript.innerHTML = `<img height="1" width="1" alt="Google Analytics" style="display:none" src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1" />`
        document.body.appendChild(noscript)
      })

      return () => {
        const script = document.querySelector(
          'script[src="https://connect.facebook.net/en_US/fbevents.js"]',
        )
        if (script) {
          script.parentNode?.removeChild(script)
        }

        fbqIds.forEach((id: any) => {
          const noscript = document.querySelector(
            `noscript img[src*="tr?id=${id}"]`,
          )
          if (noscript && noscript.parentNode) {
            noscript.parentNode.removeChild(noscript)
          }
        })
      }
    }

    let fbqCleanup: (() => void) | undefined
    if (metaTags.length) {
      fbqCleanup = loadFacebookPixel(metaTags)
    }

    return () => {
      fbqCleanup?.()
    }
  }, [metaTags])

  useEffect(() => {
    const loadGoogleTagManager = (tagId: string) => {
      const script = document.createElement('script')
      script.async = true
      script.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`
      document.head.appendChild(script)

      const inlineScript = document.createElement('script')
      inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${tagId}');
      `
      document.head.appendChild(inlineScript)

      return () => {
        document.head.removeChild(script)
        document.head.removeChild(inlineScript)
      }
    }

    let casinoGtmCleanup: (() => void) | undefined
    if (
      allCasinoGoogleTag &&
      currentDomain?.domainName === 'allcasino365.com'
    ) {
      casinoGtmCleanup = loadGoogleTagManager(allCasinoGoogleTag)
    }

    if (futur9B2cGoogleTag && currentDomain?.domainName === 'future9.club') {
      casinoGtmCleanup = loadGoogleTagManager(futur9B2cGoogleTag)
    }

    if (
      baazigerExchGoogleTag &&
      currentDomain?.domainName === 'baazigerexch.com'
    ) {
      casinoGtmCleanup = loadGoogleTagManager(baazigerExchGoogleTag)
    }

    return () => {
      casinoGtmCleanup?.()
    }
  }, [googleTag, currentDomain, allCasinoGoogleTag])

  return (
    <LiveChat
      currentDomain={currentDomain}
      isLogin={isLogin || false}
      userType={userData?.userType || ''}
    />
  )
}
