import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Advertise1 } from '../advertise/Advertise1'
import { Advertise2 } from '../advertise/Advertise2'
import { ClubLoyalty } from '../clubLoyalty/ClubLoyalty'
import { FastestOdd } from '../fastestOdd'
import { APP_SELECTED_EVENT_ID, logoutUser } from '../../../../redux'
import { useDispatch, useSelector } from 'react-redux'
import { HeaderBottom } from '../HeaderBottom'
import { GameHeader } from '../../../Game/content/GameHeader'
import { UpCommingMatches } from '../../../Sports/upcommingMatches/UpCommingMatches'
import { ICurrentBet } from '../../../Game/betslip/NewBetSlip'
import { SportsBook } from '../sportsBook'
import { HEADERS, URLType, makeGetHttpCallAsync } from '../../../../api'
import { useNavigate, useParams } from 'react-router-dom'
import { CircularProgress, useMediaQuery } from '@mui/material'
import {
  GET_ONGOING_MATCHES,
  getEventTypeFilteredEvents,
} from '../../../../redux/actions/matchesAction/SchedulesMatchesAction'
import { ICurrentDomain } from '../../../../types/interfaces/currentDomain'
import { IThemeConfiguration } from '../../../../types/interfaces/themeConfiguration'
import 'swiper/css'
import 'swiper/css/free-mode'
import {
  getLiveCasinoData,
  getVirtualCasinoData,
} from '../../../../utils/gameUtils/casinoHelper'
import {
  Navigation,
  Pagination as SwipperPagination,
  FreeMode,
  Autoplay,
} from 'swiper/modules'
import { SwiperOptions } from 'swiper/types/swiper-options'
import { getAllEventsLength } from '../../../../utils/gameUtils/gameUtil'
import StickyIcons from '../../../../component/StickyIcons'
import { sortEventsByOpenDate } from '../../../../app-util/commonUtil'
import { SAVE_STATUS_MARKETIDS } from '../../../../redux/actions/marketStatusAction'
import { InPlaySportsContent } from '../../../Game/content/InPlaySportsContent'
import { TopWinners } from '../../../../component/TopWinners'
import { CasinoAndPartnerContent } from '../../../../component/CasinoAndPartnerContent'
import { decrypt } from '../../../../api/authHelper'
import { emptyFunction } from '../../../../utils'
import { isActualFuture9 } from '../../../../App'
import { Helmet } from 'react-helmet'
import SocialMediaStickyIcons from '../../../../component/SocialMediaStickyIcons'

export interface IContent {
  isDarkMode?: boolean
  ongoingMatches: any[]
  upComingMatches: any[]
  commonGame: any
  currentBet: ICurrentBet
  fastestOdds: any[]
  currentDomain: ICurrentDomain
  marketLoader: boolean
  eventFavoriteList: any
  deletedEventId: string
  liveCasinoData: any
  virtualCasinoData: any
  addFavoriteEventList: (
    currentDomain: ICurrentDomain,
    payload: any,
    cb: any,
  ) => void
  deleteFavoriteEvent: (
    currentDomain: ICurrentDomain,
    payload: any,
    cb: any,
  ) => void
  updateOpenLoginScreen: (openLoginScreen: boolean) => void
  onMarketClick: (market: ICurrentBet) => void
  isLogin?: boolean
  hightLightMatch: any[]
  loginUser: any
  appThemeConfiguration: IThemeConfiguration
  themeConfiguration?: IThemeConfiguration
}

export const Content: FC<IContent> = (props: IContent) => {
  const { globaSearchContent } = useSelector(
    (state: any) => state.appConfiguration,
  )
  useEffect(() => {
    if (props.isLogin) {
      return dispatch(logoutUser(navigate, '', props.currentDomain) as any)
    }
    if (isActualFuture9() && props.currentDomain.type === 'b2c')
      // document.title = 'Sports Exchange | Everything you need to Win at Betting'
      // TODO: TITLE COMMENT
      return () => {
        // TODO: TITLE COMMENT
        // document.title =
        //   props?.appThemeConfiguration?.logoText +
        //   ' | Most Trusted Sports & Casino Platform'
      }
  }, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isSmall = useMediaQuery('(max-width:360px)')
  const { gameType } = useParams()
  const [marketStatus, setMarketStatus] = useState([])
  const [isMaketShown, setIsMarketShown] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const isLargeScreen = useMediaQuery('(min-width:900px)')
  const { id, userName, password, branchId } = useParams()
  const [decryptId] = decrypt(id)

  useEffect(() => {
    localStorage.setItem(HEADERS.branchId, decrypt(branchId ?? '') ?? '')
  }, [])

  useEffect(() => {
    if (password && userName) {
      props.loginUser(
        {
          email: decrypt(userName),
          password: decrypt(password),
        },
        navigate,
        emptyFunction,
        emptyFunction,
        props.currentDomain,
      )
    }
  }, [userName, password])

  const onMarketClick = useCallback(() => {
    if (isLargeScreen) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isLargeScreen])

  const swiperModules = [FreeMode, Navigation]
  const swiperOptions: SwiperOptions = {
    freeMode: true,
    wrapperClass: 'items-center fastest-odd-swiper',
    breakpoints: {
      0: {
        slidesPerView: 'auto',
        direction: 'horizontal',
        spaceBetween: 20,
      },
    },
  }

  useEffect(() => {
    if (!decryptId && props.deletedEventId) {
      dispatch({
        type: APP_SELECTED_EVENT_ID,
        payload: '',
      })
    }

    if (decryptId) {
      dispatch({
        type: APP_SELECTED_EVENT_ID,
        payload: decryptId,
      })
    }
  }, [id])

  useEffect(() => {
    if (!gameType) {
      return setIsMarketShown(false)
    }

    if (gameType === 'inPlay') {
      setIsLoader(true)
      makeGetHttpCallAsync(
        dispatch,
        'api/common/game/ongoing',
        'active=true',
        URLType.master,
        false,
        false,
      )
        .then((response: any) => {
          let marketIds = ''
          response.docs.forEach((doc: any) => {
            doc.events.forEach((event: any) => {
              if (typeof event.marketId !== 'string') {
                marketIds += `${event.marketId._id};`
              }
            })
          })

          dispatch({
            type: SAVE_STATUS_MARKETIDS,
            data: marketIds,
          })

          dispatch({
            type: GET_ONGOING_MATCHES,
            payload: sortEventsByOpenDate(
              response.docs.filter(
                (item: any) => typeof item.events[0].marketId !== 'string',
              ),
            ),
          })
          setIsLoader(false)
          return setIsMarketShown(false)
        })
        .catch(() => {
          setIsLoader(false)
        })
      return
    }

    if (isNaN(parseInt(gameType))) {
      navigate('/')
      return
    }

    setIsLoader(true)

    props.commonGame &&
      props.commonGame.length &&
      makeGetHttpCallAsync(
        dispatch,
        'api/common/game/market/status/eventType',
        `eventTypeId=${gameType}&active=true
      `,
        URLType.master,
        false,
        false,
      )
        .then((response: any) => {
          setIsLoader(false)
          setIsMarketShown(true)
          let filteredEvents: any = getEventTypeFilteredEvents(
            gameType!,
            response.docs,
            props.commonGame,
          )

          dispatch({
            type: SAVE_STATUS_MARKETIDS,
            data: filteredEvents.marketIds,
          })

          setMarketStatus(filteredEvents.res[0].events)
        })
        .catch(() => {
          dispatch({
            type: SAVE_STATUS_MARKETIDS,
            data: [],
          })

          setMarketStatus([])
          setIsLoader(false)
          setIsMarketShown(true)
        })
  }, [gameType, props.commonGame])

  const divRef = useRef<HTMLDivElement>(null)
  const [divHeight, setDivHeight] = useState<number>(0)

  useEffect(() => {
    if (divRef.current) {
      setDivHeight(divRef.current!.offsetHeight)
    }
  }, [props.ongoingMatches, props.upComingMatches])

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { registration, socialMedia } = useSelector(
    (state: any) => state.appThemeConfiguration,
  )

  return (
    <>
      {isActualFuture9() && props.currentDomain.type === 'b2c' && (
        <Helmet>
          <meta
            name='description'
            content={
              'Check out the best betting exchange sites in India. Understand what a betting exchange is, and how a sports betting exchange works.'
            }
          />
        </Helmet>
      )}

      {props.currentDomain &&
      props.currentDomain.domainName &&
      props.currentDomain.domainName === 'otp365.club' ? (
        <SocialMediaStickyIcons
          whatsappLink={registration?.whatsappNumber}
          telegramLink={registration?.telegramChannel}
          instagramLink={socialMedia?.instagram}
        />
      ) : (
        props.currentDomain &&
        props.currentDomain.type &&
        props.currentDomain.type === 'b2c' && (
          <StickyIcons
            whatsappLink={registration?.whatsappNumber}
            telegramLink={registration?.telegramChannel}
          />
        )
      )}

      <div
        className={`${props.isLogin ? 'lg:col-span-7' : 'lg:col-span-7'} md:col-span-12 pt-0 xl:col-span-7 md:mt-0 `}
      >
        <div ref={divRef}>
          <div className='mb-4'>
            <div className='block p-0 m-0 lg:hidden AtScrollHide'>
              <HeaderBottom />
            </div>
            <div className='mt-1 md:mt-0'>
              <GameHeader />
              {props.isLogin && (
                <div className='block mt-2 md:hidden pl-7'></div>
              )}
            </div>
          </div>

          <>
            {isLoader ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                }}
              >
                <CircularProgress />
              </div>
            ) : globaSearchContent && globaSearchContent.length ? (
              <UpCommingMatches
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                addFavoriteEventList={props.addFavoriteEventList}
                currentDomain={props.currentDomain}
                deleteFavoriteEvent={props.deleteFavoriteEvent}
                eventFavoriteList={props.eventFavoriteList}
                updateOpenLoginScreen={emptyFunction}
                isPopUpOpen={false}
                currentBet={props.currentBet}
                markets={globaSearchContent}
                isDarkMode={props.isDarkMode || false}
                isUpComing={false}
                isLogin={props.isLogin || false}
                onMarketClick={props.onMarketClick}
              />
            ) : (
              <>
                {isMaketShown && (
                  <div>
                    <InPlaySportsContent
                      setIsDrawerOpen={setIsDrawerOpen}
                      isDrawerOpen={isDrawerOpen}
                      isUpComing={false}
                      currentDomain={props.currentDomain}
                      addFavoriteEventList={props.addFavoriteEventList}
                      deleteFavoriteEvent={props.deleteFavoriteEvent}
                      eventFavoriteList={props.eventFavoriteList}
                      updateOpenLoginScreen={props.updateOpenLoginScreen}
                      onGoing={true}
                      isMaketShown={isMaketShown}
                      isPopUpOpen={false}
                      currentBet={props.currentBet}
                      onMarketClick={props.onMarketClick}
                      isLogin={props.isLogin || false}
                      marketStatus={marketStatus}
                    />
                  </div>
                )}

                {!isMaketShown && (
                  <div className='px-4 lg:px-0'>
                    <div>
                      <UpCommingMatches
                        setIsDrawerOpen={setIsDrawerOpen}
                        isDrawerOpen={isDrawerOpen}
                        addFavoriteEventList={props.addFavoriteEventList}
                        currentDomain={props.currentDomain}
                        deleteFavoriteEvent={props.deleteFavoriteEvent}
                        eventFavoriteList={props.eventFavoriteList}
                        updateOpenLoginScreen={props.updateOpenLoginScreen}
                        isPopUpOpen={false}
                        currentBet={props.currentBet}
                        markets={props.ongoingMatches}
                        isDarkMode={props.isDarkMode || true}
                        isUpComing={false}
                        isLogin={props.isLogin || false}
                        onMarketClick={props.onMarketClick}
                      />
                    </div>

                    {gameType !== 'inPlay' &&
                      ((!!props.upComingMatches &&
                        props.upComingMatches.length &&
                        !props.ongoingMatches.length) ||
                        (!!props.ongoingMatches &&
                          props.ongoingMatches.length &&
                          getAllEventsLength(props.ongoingMatches) < 10 &&
                          !!props.upComingMatches.length &&
                          getAllEventsLength(props.upComingMatches) < 10)) && (
                        <>
                          <UpCommingMatches
                            setIsDrawerOpen={setIsDrawerOpen}
                            isDrawerOpen={isDrawerOpen}
                            addFavoriteEventList={props.addFavoriteEventList}
                            currentDomain={props.currentDomain}
                            deleteFavoriteEvent={props.deleteFavoriteEvent}
                            eventFavoriteList={props.eventFavoriteList}
                            updateOpenLoginScreen={props.updateOpenLoginScreen}
                            isPopUpOpen={false}
                            currentBet={props.currentBet}
                            markets={props.upComingMatches}
                            isDarkMode={props.isDarkMode || false}
                            isUpComing={true}
                            isLogin={props.isLogin || false}
                            onMarketClick={props.onMarketClick}
                          />
                        </>
                      )}
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>

      <div
        className={`${props.isLogin ? 'lg:col-span-3' : 'lg:col-span-3'} md:col-span-12 px-4   xl:col-span-3  md:px-0 `}
      >
        <SportsBook
          divHeight={divHeight}
          updateOpenLoginScreen={props.updateOpenLoginScreen}
          isLogin={props.isLogin || false}
        />
      </div>

      <div className='md:col-span-12'>
        <div
          className={`pb-14  dark:!bg-none !bg-no-repeat  !bg-[length:100%_100%] grid grid-cols-9 bg-center bg-blend-multiply`}
        >
          <div className='hidden col-span-1 md:block'></div>
          <div className='col-span-9 px-4 md:px-0 md:col-span-7'>
            {!!Object.keys(props.fastestOdds).length && (
              <div
                className={`xs:mt-8 sm:mt-8 md:mt-10  md:py-10 dark:!bg-none  bg-cover bg-center bg-blend-multiply md:px-0 px-4`}
              >
                <FastestOdd
                  isLogin={props.isLogin || false}
                  setIsDrawerOpen={setIsDrawerOpen}
                  PlaceOrder={onMarketClick}
                  updateOpenLoginScreen={props.updateOpenLoginScreen}
                  fastestOdds={props.fastestOdds}
                  isDarkMode={props.isDarkMode}
                />
              </div>
            )}
          </div>
          <div className='hidden col-span-1 md:block'></div>

          <div className='hidden col-span-1 md:block'></div>
          <div className='col-span-9 px-4 md:px-0 md:col-span-7'>
            {props.marketLoader ? (
              <></>
            ) : (
              <>
                {!isMaketShown &&
                  !(globaSearchContent && globaSearchContent.length) &&
                  gameType !== 'inPlay' &&
                  !(
                    (!!props.upComingMatches &&
                      props.upComingMatches.length &&
                      !props.ongoingMatches.length) ||
                    (!!props.ongoingMatches &&
                      props.ongoingMatches.length &&
                      getAllEventsLength(props.ongoingMatches) < 10 &&
                      !!props.upComingMatches.length &&
                      getAllEventsLength(props.upComingMatches) < 10)
                  ) && (
                    <UpCommingMatches
                      setIsDrawerOpen={setIsDrawerOpen}
                      isDrawerOpen={isDrawerOpen}
                      addFavoriteEventList={props.addFavoriteEventList}
                      currentDomain={props.currentDomain}
                      deleteFavoriteEvent={props.deleteFavoriteEvent}
                      eventFavoriteList={props.eventFavoriteList}
                      updateOpenLoginScreen={props.updateOpenLoginScreen}
                      isPopUpOpen={false}
                      currentBet={props.currentBet}
                      markets={props.upComingMatches}
                      isDarkMode={props.isDarkMode || false}
                      isUpComing={true}
                      isLogin={props.isLogin || false}
                      onMarketClick={props.onMarketClick}
                    />
                  )}
              </>
            )}
          </div>
          <div className='hidden col-span-1 md:block'></div>

          <div className='hidden col-span-1 md:block'></div>
          <div className='col-span-9 px-4 md:px-0 md:col-span-7'>
            <CasinoAndPartnerContent
              getVirtualCasinoData={() => getVirtualCasinoData(_getLocalString)}
              Autoplay={Autoplay}
              SwipperPagination={SwipperPagination}
              appThemeConfiguration={props.appThemeConfiguration}
              getLiveCasinoData={() => getLiveCasinoData(_getLocalString)}
              isDarkMode={props.isDarkMode || false}
              swiperModules={swiperModules}
              swiperOptions={swiperOptions}
              themeConfiguration={props.themeConfiguration}
            />
          </div>
          <div className='hidden col-span-1 md:block'></div>
        </div>

        {props.currentDomain.type !== 'b2b' && (
          <Advertise1
            isLogin={props.isLogin || false}
            updateOpenLoginScreen={props.updateOpenLoginScreen}
          />
        )}

        {props.currentDomain.type !== 'b2b' && <TopWinners />}

        <div className={`px-4 py-14 ${isSmall ? 'mb-12' : ''}`}>
          <ClubLoyalty isDarkMode={props.isDarkMode} />
        </div>
        <Advertise2
          isLogin={props.isLogin || false}
          updateOpenLoginScreen={props.updateOpenLoginScreen}
        />
      </div>
    </>
  )
}
