import { useNavigate, useParams } from 'react-router-dom'
import {
  LiveButton,
  checkDate,
  emptyFunction,
  getAssetUrl,
} from '../../../utils'
import { ICurrentBet } from '../../Game/betslip/NewBetSlip'
import { SportsContentMarketItem } from './SportsContentMarketItem'
import { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { FaRegStar } from 'react-icons/fa'
import {
  getTranslatedSportsName,
  isMarketInPlay,
  isOpenDateBeforeCurrent,
} from '../../../utils/internalUtils'
import { ICurrentDomain } from '../../../types/interfaces/currentDomain'
import {
  DEFAULT_EXCHANGE_DATA,
  exchangeDataMap,
} from '../../../redux/type/AppConstant'
import { encrypt } from '../../../api/authHelper'
import { useSelector } from 'react-redux'
const tv = getAssetUrl('games/tv.webp')

interface ISportsContentItemProps {
  marketStatus: any
  isBetSlipLoading: boolean
  tokenExpire: any
  removeMarketIndex?: (index: number) => void
  key?: number
  noCurve?: boolean
  isLogin: boolean
  currentBet: ICurrentBet
  isPopUpOpen: boolean
  onGoing: boolean
  currentDomain: ICurrentDomain
  eventFavoriteList: any
  addFavoriteEventList: (
    currentDomain: ICurrentDomain,
    payload: any,
    cb: any,
  ) => void
  deleteFavoriteEvent: (
    currentDomain: ICurrentDomain,
    payload: any,
    eventId: any,
    cb: any,
  ) => void
  isMaketShown: boolean
  setIsDrawerOpen: any
  isUpComing: boolean
  tempStatus: any
  isDrawerOpen: boolean
  updateOpenLoginScreen: (openLoginScreen: boolean) => void
  onMarketClick: (market: ICurrentBet) => void
}

export const SportsContentItem = (props: ISportsContentItemProps) => {
  const { gameType } = useParams()
  const { tokenExpire } = useSelector((state: any) => state.appConfiguration)
  const { tournamentMatches } = useSelector((state: any) => state.inPlayMatches)
  const { isBetSlipLoading } = useSelector((state: any) => state.betSlip)

  const getMarketStatus = () => {
    return props.marketStatus.sort((a: any, b: any) => {
      const dateA = new Date(a.marketId.event.openDate).getTime()
      const dateB = new Date(b.marketId.event.openDate).getTime()

      const isEventInTournamentA = !!tournamentMatches[a.marketId.event.id]
      const isEventInTournamentB = !!tournamentMatches[b.marketId.event.id]

      if (isEventInTournamentA && !isEventInTournamentB) return 1
      if (!isEventInTournamentA && isEventInTournamentB) return -1

      return dateA - dateB
    })
  }

  return (
    <div className=''>
      <div
        className={`md:grid hidden  grid-cols-12 xl:pr-4 items-center text-white  dark:!bg-[var(--inPlay-match-background)] !bg-white shadow-[0px_4px_44px_0px_#0000001a]`}
      >
        <div className='min-h-[50px]  md:w-auto w-[150px] text-white bg-gradient-to-r col-span-3 from-[var(--inPlay-match-gradientFrom)] to-[var(--inPlay-match-gradientTo)] py-4 px-5 flex items-center font-bold text-lg capitalize'>
          <img
            src={getAssetUrl(
              `sports/png/${
                gameType && DEFAULT_EXCHANGE_DATA.has(gameType || '')
                  ? DEFAULT_EXCHANGE_DATA.get(gameType || '')
                  : props.marketStatus[0]?.marketId?.eventType?.name
                      .replace(/\s/g, '')
                      .toLowerCase()
              }.webp`,
            )}
            alt='Sports Icon'
            style={{ height: '1rem', marginRight: '1rem' }}
          />
          {props?.marketStatus &&
          props?.marketStatus.length &&
          props.marketStatus[0].marketId &&
          props.marketStatus[0].marketId.eventType
            ? getTranslatedSportsName(
                props.marketStatus[0].marketId.eventType.name,
                _getLocalString,
              )
            : exchangeDataMap.has(gameType!)
              ? getTranslatedSportsName(
                  exchangeDataMap.get(gameType!) || '',
                  _getLocalString,
                )
              : _getLocalString('LOC_NO_DATA_FOUND')}
        </div>

        <div className='col-span-5 '></div>

        <div className='flex justify-around col-span-4 '>
          <div className='pr-3 font-normal leading-5 tracking-normal text-center text-black text-2l dark:text-white font-primary'>
            1
          </div>
          <div className='pr-3 font-normal leading-5 tracking-normal text-center text-black text-2l dark:text-white font-primary'>
            X
          </div>
          <div className='pr-3 font-normal leading-5 tracking-normal text-center text-black text-2l dark:text-white font-primary'>
            2
          </div>
        </div>
      </div>

      <div
        className={` grid md:hidden sm:grid-cols-3  items-center text-white bg-transparent  dark:bg-[var(--inPlay-match-background)]   `}
      >
        <div className='gap-4 text-white bg-gradient-to-r md:col-span-2 from-[var(--inPlay-match-gradientFrom)] to-[var(--inPlay-match-gradientTo)] lg:py-4 sm:py-3 py-2 px-3 text-[15px] flex capitalize  items-center font-bold'>
          {props?.marketStatus &&
          props?.marketStatus.length &&
          props.marketStatus[0].marketId &&
          props.marketStatus[0].marketId.eventType
            ? props.marketStatus[0].marketId.eventType.name
            : _getLocalString('LOC_NO_DATA_FOUND')}
        </div>
      </div>
      <div className='grid grid-cols-3 col-span-3  bg-white dark:bg-[#FFEFF2] text-black border-t md:hidden'>
        <div className='bold text-center '> 1 </div>
        <div className='bold text-center'> X </div>
        <div className='bold text-center '> 2 </div>
      </div>
      {props.marketStatus && props.marketStatus?.length ? (
        getMarketStatus()?.map((match: any, index: any) => {
          return (
            <SportsContentItemContent
              isBetSlipLoading={isBetSlipLoading}
              tokenExpire={tokenExpire}
              tempStatus={props.tempStatus}
              isDrawerOpen={props.isDrawerOpen}
              setIsDrawerOpen={props.setIsDrawerOpen}
              isUpComing={props.isUpComing}
              isMaketShown={props.isMaketShown}
              removeMarketIndex={props.removeMarketIndex}
              noCurve={props.noCurve}
              addFavoriteEventList={props.addFavoriteEventList}
              currentDomain={props.currentDomain}
              deleteFavoriteEvent={props.deleteFavoriteEvent}
              eventFavoriteList={props.eventFavoriteList}
              updateOpenLoginScreen={props.updateOpenLoginScreen}
              onGoing={props.onGoing}
              isPopUpOpen={props.isPopUpOpen}
              currentBet={props.currentBet}
              onMarketClick={props.onMarketClick}
              isLogin={props.isLogin}
              marketStatus={match}
              key={index}
            />
          )
        })
      ) : (
        <div className='dark:text-white text-black bold mt-5 text-center'>
          {_getLocalString('LOC_COMMIN_SOON')}
        </div>
      )}
    </div>
  )
}

export const SportsContentItemContent = (props: ISportsContentItemProps) => {
  const navigate = useNavigate()
  const market = props.marketStatus ? props.marketStatus.marketId : undefined

  const isSmallScreen = useMediaQuery('(max-width:900px)')

  const [inPlay, setInPlay] = useState(
    isMarketInPlay(props.marketStatus.marketId),
  )

  const noMarketStatus = () => {
    props.removeMarketIndex && props.removeMarketIndex(props.key || 0)
  }

  const getIsMatchAllowed = () => {
    if (!props.isUpComing) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (
      Object.keys(props.currentBet).length &&
      Object.keys(props.currentBet.marketId).length
    ) {
    } else {
      props.setIsDrawerOpen(false)
    }
  }, [props.currentBet])

  useEffect(() => {
    market && setInPlay(isMarketInPlay(market))
  }, [market])

  const isSmall = useMediaQuery('(max-width:500px)')
  const isVerySmallScreen = useMediaQuery('(max-width:400px)')
  const isExtraSmallScreen = useMediaQuery('(max-width:380px)')
  const isMoreExtraSmallScreen = useMediaQuery('(max-width:350px)')
  const isScreenSmall = useMediaQuery('(max-width:350px)')

  const marketClick = (market: ICurrentBet) => {
    props.onMarketClick(market)

    if (!isSmallScreen) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const handleFavoriteClick = (event: { stopPropagation: () => void }) => {
    event.stopPropagation()
    var urlencoded = new URLSearchParams()

    if (!props.isLogin) {
      props.updateOpenLoginScreen(true)
      return
    }

    if (!props.eventFavoriteList) {
      urlencoded.append('eventId', market.event.id)
      return props.addFavoriteEventList(
        props.currentDomain,
        urlencoded,
        emptyFunction,
      )
    }

    if (props.eventFavoriteList[market.event.id]) {
      urlencoded.append('eventId', market.event.id)
      props.deleteFavoriteEvent(
        props.currentDomain,
        urlencoded,
        market.event.id,
        emptyFunction,
      )
    } else {
      urlencoded.append('eventId', market.event.id)
      props.addFavoriteEventList(props.currentDomain, urlencoded, emptyFunction)
    }
  }

  const isSmallWidth400 = useMediaQuery('(max-width:400px)')
  const isScreenWidth380 = useMediaQuery('(max-width:380px)')
  const isScreenWidth340 = useMediaQuery('(max-width:280px)')

  const getMarketStatus = () => {
    let marketStatusLocal = props.marketStatus
    if (props.tempStatus && market && props.tempStatus.get(market._id)) {
      marketStatusLocal = props.tempStatus.get(market._id)
    }
    return marketStatusLocal
  }

  const copyMarketName = (marketName: string) => {
    navigator.clipboard.writeText(marketName)
  }

  return (
    <div
      className={`text-black py-1 ${props.noCurve ? '' : 'last-of-type:rounded-b-xl'} bg-white md:gap-5 gap-2 dark:bg-[#FFEFF2] sm:px-5 px-1  flex md:flex-row flex-col items-center  justify-between  dark:shadow-none shadow-md border-solid border-[#E8E8E8] border-b-[1px]`}
    >
      <div className=' w-full flex items-center justify-between grid grid-cols-12'>
        {/* ---------------Grid 1--------- */}
        <div className='flex items-center col-span-10'>
          {market ? (
            <>
              <div className='flex grid grid-cols-12'>
                {/* ---------------------Div 1------------------- */}
                <div
                  className={`flex items-center ${isScreenSmall ? 'col-span-6' : 'xs:col-span-5 sm:col-span-6 md:col-span-3'}`}
                >
                  <div
                    className='flex cursor-pointer md:block hover-div'
                    onClick={handleFavoriteClick}
                  >
                    <div className=' items-center order-2 px-2 overflow-hidden !text-sm font-bold leading-5 border-l-2 md:text-base md:order-1 md:px-0 md:border-0 md:font-medium sm:max-w-full max-w-[130px] sm:whitespace-nowrap'>
                      {!!props.eventFavoriteList &&
                      !!props.eventFavoriteList[
                        market && market.event && market.event.id
                      ] ? (
                        <img
                          src={getAssetUrl('header/favourite.webp')}
                          style={{
                            filter: 'drop-shadow(0px 0px 0.5px black)',
                            width: '1rem',
                            height: '1rem',
                          }}
                          alt='Favorite'
                        />
                      ) : (
                        <FaRegStar style={{ width: '1rem', height: '1rem' }} />
                      )}
                    </div>
                  </div>

                  <div
                    className={`${
                      isSmallWidth400
                        ? 'w-[94px]'
                        : isScreenWidth380
                          ? 'w-[90px]'
                          : isScreenWidth340
                            ? 'w-[80px]'
                            : 'xs:w-[102px] sm:w-[100px] md:w-[50px] sm:ml-2 xs:mt-[2px] md:mt-0 relative inline-block flex items-center justify-center'
                    }`}
                  >
                    <div
                      className=' xs:pr-1 sm:pr-0 flex flex-col items-center justify-center'
                      onClick={() => copyMarketName(market.event.name)}
                    >
                      {isOpenDateBeforeCurrent(market.event.openDate) ? (
                        <div className='ml-2'>
                          <LiveButton />
                        </div>
                      ) : (
                        <span className='lg:text-[14px] sm:text-[12px]'>
                          {checkDate(market.event.openDate, _getLocalString)}
                        </span>
                      )}
                      <div className='!text-sm md:hidden block  font-medium md:!text-base font-dm_sans text-center !leading-[1.5]'>
                        <span className='sm:whitespace-nowrap'>
                          {market && market.event && market.event.openDate && (
                            <>
                              <div className='whitespace-nowrap flex gap-2'>
                                <div>
                                  {new Date(
                                    market.event.openDate,
                                  ).toLocaleDateString(undefined, {
                                    timeZone:
                                      Intl.DateTimeFormat().resolvedOptions()
                                        .timeZone,
                                    weekday: 'short',
                                  })}
                                </div>
                                <div>
                                  {new Date(
                                    market.event.openDate,
                                  ).toLocaleTimeString(undefined, {
                                    timeZone:
                                      Intl.DateTimeFormat().resolvedOptions()
                                        .timeZone,
                                    hour: '2-digit',
                                    minute: '2-digit',
                                  })}
                                </div>
                              </div>
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ------------------------------Div 2-------------------- */}
                <div
                  className={` ${isScreenSmall ? 'col-span-6' : 'xs:col-span-7 sm:col-span-6 md:col-span-9'} flex cursor-pointer md:block hover-div md:ml-4`}
                  onClick={() => {
                    !props.isLogin
                      ? props.updateOpenLoginScreen(true)
                      : navigate(`/events/${encrypt(market.event.id)}`)
                  }}
                >
                  <div
                    style={{
                      width: '14rem',
                      display: 'block',
                    }}
                    className={`w-full whitespace-nowrap flex items-center order-2 px-2 !text-[13px] sm:!text-sm font-bold leading-5 border-l-2 md:text-base md:order-1 md:px-0 md:border-0 md:font-medium xs:max-w-[200px] sm:max-w-[250px]`}
                  >
                    {market && market.event && market.event.name && (
                      <span
                        className=' text-balance md:!text-nowrap'
                        style={{
                          display: 'block',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        {isMoreExtraSmallScreen
                          ? market.event.name
                          : market.event.name}
                      </span>
                    )}
                  </div>

                  {/* -----------------------Big Screen Date---------------------- */}
                  <div className='md:block hidden !text-[13px] basis-1/3 md:px-0 px-3 font-dm_sans whitespace-pre-line'>
                    {market &&
                      market.event &&
                      market.event.openDate &&
                      new Date(market.event.openDate).toLocaleString('en-US', {
                        timeZone:
                          Intl.DateTimeFormat().resolvedOptions().timeZone,
                        weekday: 'short',
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              className='flex py-2 cursor-pointer md:block hover-div'
              onClick={() => {
                !props.isLogin
                  ? props.updateOpenLoginScreen(true)
                  : navigate(`/events/${encrypt(props.marketStatus.eventId)}`)
              }}
            >
              <div className='items-center order-2 w-full px-3 overflow-hidden text-base font-bold leading-5 border-l-2 md:order-1 md:px-0 md:border-0 md:font-medium whitespace-nowrap text-ellipsis'>
                {_getLocalString('LOC_LOADING')}...
              </div>
            </div>
          )}
        </div>

        {/* ------------Grid 2----------------- */}
        {!isScreenWidth340 && (
          <div className='grid col-span-2 items-center justify-end'>
            <div className='flex items-center justify-center gap-4'>
              {true ? (
                <div className='flex items-center justify-center'>
                  <img
                    src={tv}
                    alt='TV'
                    className='w-auto md:h-[18px] lg:h-[20px] sm:h-[16px] h-[12px] cursor-pointer'
                  />
                </div>
              ) : (
                <div className='flex items-center justify-center'></div>
              )}
              <div className='items-center hidden md:flex justify-evenly'>
                {props.onGoing ? (
                  <div className='w-3 h-3 rounded-full'>
                    <img
                      alt='In Play'
                      src={getAssetUrl('asset/inplay2.webp')}
                    />
                  </div>
                ) : (
                  <div className='w-3 h-3'></div>
                )}
              </div>

              {market && (
                <div className='flex items-center justify-center'>
                  <div className='text-[black] bold text-sm flex items-center justify-center'>
                    {props.marketStatus.marketId &&
                      props.marketStatus.marketId.type === 'bookmaker' &&
                      'BM'}
                    {props.marketStatus.marketId &&
                      props.marketStatus.marketId.type === 'session' &&
                      props.marketStatus.marketId.externalType === 'betfair' &&
                      'F'}
                  </div>
                  <div className='text-[black] bold text-sm flex items-center justify-center'>
                    {props.marketStatus.marketId &&
                      props.marketStatus.marketId.type === 'betfair' &&
                      'BF'}
                    {!(
                      props.marketStatus.marketId &&
                      props.marketStatus.marketId.type === 'betfair'
                    ) &&
                      !(
                        props.marketStatus.marketId &&
                        props.marketStatus.marketId.type === 'bookmaker'
                      ) &&
                      !(
                        props.marketStatus.marketId &&
                        props.marketStatus.marketId.type === 'session' &&
                        props.marketStatus.marketId.externalType === 'betfair'
                      ) &&
                      '---'}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='md:w-[32%] w-full'>
        {market && Object.keys(market).length && market._id && (
          <SportsContentMarketItem
            tokenExpire={props.tokenExpire}
            matchNotAllowed={getIsMatchAllowed()}
            isDrawerOpen={props.isDrawerOpen}
            isBetSlipLoading={props.isBetSlipLoading}
            setIsDrawerOpen={props.setIsDrawerOpen}
            updateOpenLoginScreen={props.updateOpenLoginScreen}
            onMarketClick={marketClick}
            isLogin={props.isLogin}
            inPlay={inPlay}
            noMarketStatus={noMarketStatus}
            market={market}
            marketStatus={getMarketStatus()}
          />
        )}
      </div>
    </div>
  )
}
