export const ADDDED_DOMAIN: any = {
  'future9.club': {
    whitelabelId: '668e9e5ea1da9ac742bb8a4c',
    type: 'b2c',
    domainName: 'future9.club',
  },
  'allstake9.club': {
    whitelabelId: '668e9e5ea1da9ac742bb8a4c',
    type: 'b2c',
    domainName: 'allstake9.club',
  },
  'rishubook.in': {
    whitelabelId: '668eb1bd35a4fbdee4a16315',
    type: 'b2c',
    domainName: 'rishubook.in',
  },
  'allcasino365.com': {
    whitelabelId: '668eb5ab35a4fbdee4a16438',
    type: 'b2c',
    domainName: 'allcasino365.com',
  },
  'test.future9.club': {
    whitelabelId: '668fdce4c74e83e5230bc9bd',
    type: 'b2c',
    domainName: 'test.future9.club',
  },
  'lazer7.com': {
    whitelabelId: '668c1ccca3bff9a4af43b130',
    type: 'b2c',
    domainName: 'lazer7.com',
  },
  'pr.lazer7.com': {
    whitelabelId: '668c1ccca3bff9a4af43b130',
    type: 'b2c',
    domainName: 'lazer7.com',
  },
  'edgebet9.com': {
    whitelabelId: '66cc8a464c0b25dceb944fb5',
    type: 'b2c',
    domainName: 'edgebet9.com',
  },
  'winanna247.com': {
    whitelabelId: '66d185a2c2aa805ccebf7f91',
    type: 'b2c',
    domainName: 'winanna247.com',
  },
  'pp.future9.club': {
    whitelabelId: '66d205e76ca3147f55b01b9c',
    type: 'b2c',
    domainName: 'pp.future9.club',
  },
  'jannat111.games': {
    whitelabelId: '66d303eaa84390ae6ca48693',
    type: 'b2c',
    domainName: 'jannat111.games',
  },
  'parimatchbook.ai': {
    whitelabelId: '66d3046764a010fa0456fc61',
    type: 'b2c',
    domainName: 'parimatchbook.ai',
  },
  '10exchange.club': {
    whitelabelId: '66dc3ff249540d06e370c96e',
    type: 'b2c',
    domainName: '10exchange.club',
  },
  'mamabook.club': {
    whitelabelId: '66d30365a84390ae6ca48219',
    type: 'b2c',
    domainName: 'mamabook.club',
  },
  'baazigerexch.com': {
    whitelabelId: '66e16d38e39b5babee049c07',
    type: 'b2c',
    domainName: 'baazigerexch.com',
  },
  'otp365.club': {
    whitelabelId: '66e55d7e9fbb30c8a31ef5dd',
    type: 'b2c',
    domainName: 'otp365.club',
  },
  'toyo247.ai': {
    whitelabelId: '66eb05b2fafbb56c3310a313',
    type: 'b2c',
    domainName: 'toyo247.ai',
  },
  '10bat.com': {
    whitelabelId: '66f94b36628684e511e5eb90',
    type: 'b2c',
    domainName: '10bat.com',
  },
  'poker555.vip': {
    whitelabelId: '66f946c8628684e511e5a5b4',
    type: 'b2c',
    domainName: 'poker555.vip',
  },
  'winwave247.com': {
    whitelabelId: '66f94ac4a97a8683e429d325',
    type: 'b2c',
    domainName: 'winwave247.com',
  },
  'bhawanibet.com': {
    whitelabelId: '6707b9fc1aee82070328d068',
    type: 'b2c',
    domainName: 'bhawanibet.com',
  },
  'simplywin.net': {
    whitelabelId: '670be32f6da2dccfb09853c8',
    type: 'b2c',
    domainName: 'simplywin.net',
  },
  'playprofits.games': {
    whitelabelId: '670be2cb6da2dccfb09836cd',
    type: 'b2c',
    domainName: 'playprofits.games',
  },

  'live.lazer7.com': {
    whitelabelId: '668c1af0a346e90cb30ff0b8',
    type: 'b2b',
    domainName: 'live.lazer7.com',
  },
  'allstake9.com': {
    whitelabelId: '668e9f6adf3e5e3b343367bb',
    type: 'b2b',
    domainName: 'allstake9.com',
  },
  'tataexch247.com': {
    whitelabelId: '66c71ea9e5e97fda79c7b281',
    type: 'b2b',
    domainName: 'tataexch247.com',
  },
  'future9.com': {
    whitelabelId: '668e9f6adf3e5e3b343367bb',
    type: 'b2b',
    domainName: 'future9.com',
  },
  'pp.future9.com': {
    whitelabelId: '66d206cd482b5f04ee917df9',
    type: 'b2b',
    domainName: 'pp.future9.com',
  },
  'betbhai9.ac': {
    whitelabelId: '66edc1383075d1c57ccce538',
    type: 'b2b',
    domainName: 'betbhai9.ac',
  },
  'goldenexch9.com': {
    whitelabelId: '66faa5e4938f5afb6169bb42',
    type: 'b2b',
    domainName: 'goldenexch9.com',
  },
  'poker555.in': {
    whitelabelId: '66faa593938f5afb6169bb14',
    type: 'b2b',
    domainName: 'poker555.in',
  },
  'vishnu9.com': {
    whitelabelId: '66fe99cffea57374548325f9',
    type: 'b2b',
    domainName: 'vishnu9.com',
  },
  'test.future9.com': {
    whitelabelId: '66ffc437e5e7ab653f3e4bb2',
    type: 'b2b',
    domainName: 'test.future9.com',
  },
  'peter777.com': {
    whitelabelId: '66ffc4c6e5e7ab653f3e4c3c',
    type: 'b2b',
    domainName: 'peter777.com',
  },
  'lemonexchange9.com': {
    whitelabelId: '670262393e31b544c73634dd',
    type: 'b2b',
    domainName: 'lemonexchange9.com',
  },
  'moonbets9.com': {
    whitelabelId: '6707adb58295998dfab8f612',
    type: 'b2b',
    domainName: 'moonbets9.com',
  },
}
