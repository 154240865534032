export const GET = 'GET'
export const POST = 'POST'
export const DELETE = 'DELETE'
export const PATCH = 'PATCH'
export const PUT = 'PUT'

export const HEADERS = {
  language: 'language',
  branchId: 'branchId',
  tempPassword: 'tempPassword',
  contentTypeKey: 'Content-Type',
  contentTypeValue: 'application/json; charset=utf-8',
  xAuthentication: 'X-Authentication',
  Authorization: 'authorization',
  secretKey: 'secretKey',
  redirect: 'redirect',
  follow: 'follow',
  userType: 'userType',
  domain: 'domain',
  changePassword: 'changePassword',
  enable2FA: 'enable2FA',
}

export enum URLType {
  'master' = 'master',
  'b2b' = 'b2b',
  'b2c' = 'b2c',
}

export const MARKET_STATUS_CALL_NON_LOGIN = 10000
export const MARKET_STATUS_CALL_LOGIN = 1500
export const LIVE_TV_REFRESH_RATE = 60000
export const MARKET_INTERNAL_STATUS_CALL_LOGIN = 500
export const OTP_RESEND_TIME = 60
export const POINT_UPDATE_TIME = 15000
export const IS_SCORE_SOCKET_ENABLED = true

// DEFAULT LOADER
export const DOMAIN_LOADER = false
export const DEFAULT_DOMAIN_LOADER = true
export const NOT_RELEASED = false

export const IS_LOCAL_FUTURE9 = true
export const DEFAULT_WEBSITE_NAME = 'future9'
export const DEFAULT_LAZER7_WEBSITE_NAME = 'lazer7'
export const DEFAULT_TEST_WEBSITE_NAME = 'test'
export const DEFAULT_PR_WEBSITE_NAME = 'pr'
export const STAGE_BASE_URL = 'lazer7.com'
export const COMMON_WEBSITE = 'allstake9'
export const PROD_BASE_URL = 'test.future9.club'
export const DEFAULT_LIMIT_FOR_REPORT_USER = 25
export const UNKNOWN = 'Unknown'
export const IN_ACTIVITY_LOGOUT = 3600000 // 01 Hours
export const NON_LOGIN_LOGOUT = 7200000 // 02 Hours
export const DEPOSIT_PAGE_HIDE_DETAIL: any = { 'otp365.club': true }
export const LIGHT_THEME: any = { 'otp365.club': true }
export const TOP_CASINO_THEME: any = { 'test.future9.club': true }
