import { useDispatch, useSelector } from 'react-redux'
import {
  ADDED_MARKET,
  APP_CONFIGURATION_SOCKET_BLOCKED,
  APP_SELECTED_RESULT_MARKET,
  APP_SELECTED_RESULT_MARKETS,
  DELETED_MARKET,
  DEPOSIT_ADDED,
  getCacheMarket,
  getCommunicationDetail,
  getFavoriteEvent,
  getFavoriteMarket,
  getHomeData,
  getProfile,
  getUserCasinoList,
  logoutUser,
  POINT_UPDATE,
  TOKEN_EXPIRE,
  UPDATE_MODIFY_MARKET,
  USER_ADDED,
  WITHDRAW_ADDED,
} from './redux'
import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { getSocketUrl, HEADERS, IS_SCORE_SOCKET_ENABLED } from './api'
import { io } from 'socket.io-client'
import {
  AddBetFairMarketStatus,
  AddBookMakerMarketStatus,
  AddPremiumMarketStatus,
  AddSessionMarketStatus,
  deleteFromMarketStatus,
  removeMarkStatus,
} from './redux/actions/marketStatusAction'
import { getQuickBet, getUserStakes } from './redux/actions/userAction'
import {
  getOpenBet,
  updateOpenBet,
} from './redux/actions/reportAction/reportsAction'
import { emptyFunction, getThemeAssetUrl } from './utils'
import { toast } from 'react-toastify'
import { DUPLICATE_SCREEN } from './redux/actions/maintenanceAction'
import { getHighLightMatch } from './redux/actions/userAction/highlightsAction'
import {
  addRemoveScore,
  GET_MATCHES_LIVE_TV,
  getGameEventScoreAndLiveTV,
  getTournament,
} from './redux/actions/matchesAction/SchedulesMatchesAction'
import { SAVE_SCORE } from './redux/reducers/userReducer/ScoreReducer'
import { MARKET_TYPE } from './types/enums/gameType'

export const getSocket = (currentDomain: any) => {
  const token =
    localStorage.getItem(HEADERS.xAuthentication) ??
    `${sessionStorage.getItem(HEADERS.xAuthentication)}`
  return io(getSocketUrl(), {
    transports: ['websocket'],
    reconnection: true,
    withCredentials: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 10000,
    reconnectionDelayMax: 5000,
    randomizationFactor: 0.5,
    extraHeaders: {
      authorization: token,
      type: currentDomain.type,
      secretkey: import.meta.env.VITE_API_SECRET_KEY as string,
    },
    query: {
      authorization: token,
      type: currentDomain.type,
      secretkey: import.meta.env.VITE_API_SECRET_KEY as string,
    },
  })
}

export const SocketConnection = () => {
  const { isLogin, userData } = useSelector((state: any) => state.login)
  const currentDomain = useSelector((state: any) => state.currentDomain)
  const [socket, setSocket] = useState<any>(undefined)
  const { marketRequest, eventRequest } = useSelector(
    (state: any) => state.adminGames,
  )
  const {
    deletedEventId,
    isMarketAddedLoad,
    isMarketDeletedload,
    siteLoader,
    deletedMarketList,
    useSocket,
    resultDeclaredMarketIds,
    selectedEventId,
  } = useSelector((state: any) => state.appConfiguration)
  const marketStatus = useSelector((state: any) => state.marketStatus)
  const { liveTvMatches } = useSelector((state: any) => state.inPlayMatches)
  const { logoSmallUrl } = useSelector(
    (state: any) => state.appThemeConfiguration,
  )

  const handleNotification = async (
    title: string,
    description: string,
    image: string,
  ) => {
    if (Notification.permission === 'granted') {
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(title, {
            body: description,
            icon: logoSmallUrl && getThemeAssetUrl(logoSmallUrl),
            image: image && getThemeAssetUrl(image),
          } as any)
        })
      } else {
        new Notification(title, {
          body: description,
          icon: logoSmallUrl && getThemeAssetUrl(logoSmallUrl),
          image: image && getThemeAssetUrl(image),
        } as any)
      }
    } else {
      console.error('Notification permission denied')
    }
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const checkSocket = useCallback(() => {
    if (
      (!socket || !socket.connected) &&
      isLogin &&
      currentDomain &&
      currentDomain.type
    ) {
      socket && socket.disconnect()
      dispatch({
        type: APP_CONFIGURATION_SOCKET_BLOCKED,
        payload: false,
      })
      let localSocket = getSocket(currentDomain)
      dispatch({
        type: APP_CONFIGURATION_SOCKET_BLOCKED,
        payload: true,
      })
      setSocket(localSocket)
    }
  }, [isLogin, currentDomain])

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    const stopApiCalls = () => {
      clearInterval(intervalId)
    }

    intervalId = setInterval(checkSocket, 500)

    return () => {
      stopApiCalls()
    }
  }, [])

  useEffect(() => {
    dispatch(getUserCasinoList(currentDomain, 'Live Casino') as any)
  }, [])

  useEffect(() => {
    if (isLogin && !!socket && !!deletedEventId) {
      useSocket &&
        socket.emit(
          'deleteEventSubscription',
          { eventId: deletedEventId },
          () => {},
        )

      if (IS_SCORE_SOCKET_ENABLED) {
        dispatch(removeMarkStatus() as any)
        socket.emit(
          'deleteEventScoreSubscription',
          { eventId: deletedEventId },
          () => {},
        )
      }
    }

    if (isLogin && !!socket && !!selectedEventId) {
      useSocket &&
        dispatch(removeMarkStatus() as any) &&
        socket.emit(
          'postEventSubscription',
          { eventId: selectedEventId },
          () => {},
        )

      if (IS_SCORE_SOCKET_ENABLED) {
        socket.emit(
          'postEventScoreSubscription',
          { eventId: selectedEventId },
          () => {},
        )
      }
    }
  }, [selectedEventId, deletedEventId, isLogin, socket])

  useEffect(() => {
    const token =
      localStorage.getItem(HEADERS.xAuthentication) ??
      sessionStorage.getItem(HEADERS.xAuthentication)
    let userType = localStorage.getItem(HEADERS.userType)
    if (
      (isLogin && !!socket) ||
      (token &&
        userType &&
        userType !== 'undefined' &&
        eventRequest &&
        Object.keys(eventRequest).length &&
        socket &&
        currentDomain &&
        currentDomain.type !== '' &&
        localStorage.getItem(HEADERS.xAuthentication))
    ) {
      socket.emit('getAllEvent', eventRequest, (data: any) => {
        console.log('emit getAllEvent', data)
      })
    }
  }, [eventRequest])

  useEffect(() => {
    const token =
      localStorage.getItem(HEADERS.xAuthentication) ??
      sessionStorage.getItem(HEADERS.xAuthentication)
    let userType = localStorage.getItem(HEADERS.userType)
    if (
      (isLogin && !!socket) ||
      (token &&
        userType &&
        userType !== 'undefined' &&
        marketRequest &&
        Object.keys(marketRequest).length &&
        socket &&
        currentDomain &&
        currentDomain.type !== '')
    ) {
      socket.emit('getAllMarket', marketRequest, (data: any) => {
        console.log('emit getAllMarket', data)
      })
    }
  }, [marketRequest])

  useEffect(() => {
    if (currentDomain && currentDomain.type !== '') {
      if (currentDomain.type === 'b2c' && userData?.userType === 'user') {
        dispatch(getCommunicationDetail(userData?.createdBy) as any)
      }

      if (isLogin && userData?.userType === 'user') {
        dispatch(getUserStakes(currentDomain) as any)
        dispatch(getFavoriteEvent(currentDomain) as any)
        dispatch(getQuickBet(currentDomain) as any)
        dispatch(
          getOpenBet(currentDomain, '', false, emptyFunction, false) as any,
        )
        dispatch(getFavoriteMarket(currentDomain) as any)
      }
    }

    let hashMapDeletedMarketList = deletedMarketList
      ? new Map(deletedMarketList.map((obj: any) => [obj, true]))
      : new Map()
    let hashMapResultMarketList = resultDeclaredMarketIds
      ? new Map(resultDeclaredMarketIds.map((obj: any) => [obj, true]))
      : new Map()
    const token =
      localStorage.getItem(HEADERS.xAuthentication) ??
      sessionStorage.getItem(HEADERS.xAuthentication)
    let userType: string = localStorage.getItem(HEADERS.userType) || ''
    let domainUpdate = localStorage.getItem(HEADERS.domain)
      ? JSON.parse(localStorage.getItem(HEADERS.domain) || '')
      : ''
    let localSocket = socket

    if (!isLogin && localSocket) {
      dispatch({
        type: APP_CONFIGURATION_SOCKET_BLOCKED,
        payload: true,
      })
      localSocket.disconnect()
    }

    if (!siteLoader) {
      if (isLogin) {
        if (localSocket && !localSocket.connected) {
          localSocket.disconnect()
          localSocket = getSocket(currentDomain)
          setSocket(localSocket)
          dispatch({
            type: APP_CONFIGURATION_SOCKET_BLOCKED,
            payload: false,
          })
        } else if (
          !localSocket &&
          currentDomain &&
          currentDomain.type !== '' &&
          (localStorage.getItem(HEADERS.xAuthentication) ??
            sessionStorage.getItem(HEADERS.xAuthentication))
        ) {
          localSocket = getSocket(currentDomain)
          dispatch({
            type: APP_CONFIGURATION_SOCKET_BLOCKED,
            payload: false,
          })
          setSocket(localSocket)
        }
      } else if (
        !(
          token &&
          userType &&
          domainUpdate &&
          domainUpdate.type !== '' &&
          userType !== 'undefined'
        )
      ) {
        if (localSocket) {
          dispatch({
            type: APP_CONFIGURATION_SOCKET_BLOCKED,
            payload: true,
          })
          localSocket.disconnect()
        }
        return
      }
    }

    if (localSocket) {
      localSocket.on('connection', (data: any) => {
        console.log(
          '🌟 CONNECTION ESTABLISHED: SOCKET SUCCESSFULLY CONNECTED 🌟',
        )
        dispatch({
          type: APP_CONFIGURATION_SOCKET_BLOCKED,
          payload: false,
        })

        localSocket.on('getBroadcastNotification', (data: any) => {
          console.log('getBroadcastNotification')
          handleNotification(
            data.data.title,
            data.data.description,
            data.data.image,
          )
        })

        localSocket.on('manualDisconnect', () => {
          console.log('SOCKET MANUAL --- DISCONNECT')
          toast.error('Duplicate Window or Un Authorised access')
          setTimeout(() => {
            dispatch({
              type: DUPLICATE_SCREEN,
              payload: true,
            })
          }, 5000)
        })

        if (isLogin) {
          localSocket.on('getPtsUpdates', (data: any) => {
            console.log('SOCKET --- POINTS UPDATE')
            dispatch({
              type: POINT_UPDATE,
              payload: true,
            })
          })

          if (userType === 'user') {
            localSocket.on('highlightUpdated', (data: any) => {
              dispatch(getHighLightMatch() as any)
            })

            localSocket.on('getRefreshPage', () => {
              window.location.reload()
            })

            localSocket.on('logoutUserByAdmin', () => {
              dispatch(logoutUser(navigate, '', currentDomain) as any)
            })

            localSocket.on('getBetfairTokenExpired', () => {
              dispatch({
                type: TOKEN_EXPIRE,
                payload: true,
              })
            })

            localSocket.on('getBetfairTokenRefreshed', () => {
              dispatch({
                type: TOKEN_EXPIRE,
                payload: false,
              })
            })

            localSocket.on('getOpenBetResultUpdates', () => {
              console.log('SOCKET --- RESULT')
              dispatch(
                getOpenBet(
                  currentDomain,
                  '',
                  false,
                  emptyFunction,
                  false,
                ) as any,
              )
              dispatch(
                getProfile(navigate, 'user', currentDomain, false) as any,
              )
            })

            // GET EVENT STATUS
            localSocket.on('getEventStatus', (data: any) => {
              console.log('SOCKET --- EVENT STATUS')
              if (data.docs.status === 'result') {
                dispatch(deleteFromMarketStatus(data.docs, marketStatus) as any)

                dispatch({
                  type: APP_SELECTED_RESULT_MARKET,
                  payload: data.docs,
                })

                dispatch({
                  type: APP_SELECTED_RESULT_MARKETS,
                  payload: data.docs.marketId.id,
                })
              }

              if (hashMapResultMarketList.get(data.docs.marketId.id)) {
                return
              }

              if (hashMapDeletedMarketList.get(data.docs.marketId.id)) {
                return
              }

              if (
                !selectedEventId ||
                (data.docs.eventId !== deletedEventId &&
                  data.docs.eventId === selectedEventId)
              ) {
                if (
                  data.docs.marketId.type === MARKET_TYPE.BETFAIR &&
                  data.docs.marketId.externalType === MARKET_TYPE.BETFAIR
                ) {
                  dispatch(
                    AddPremiumMarketStatus(data.docs, marketStatus) as any,
                  )
                } else if (data.docs.marketId.type === MARKET_TYPE.BETFAIR) {
                  dispatch(
                    AddBetFairMarketStatus(data.docs, marketStatus) as any,
                  )
                } else if (data.docs.marketId.type === MARKET_TYPE.BOOKMAKER) {
                  dispatch(
                    AddBookMakerMarketStatus(data.docs, marketStatus) as any,
                  )
                } else if (data.docs.marketId.type === MARKET_TYPE.SESSION) {
                  dispatch(
                    AddSessionMarketStatus(data.docs, marketStatus) as any,
                  )
                }
              }
            })

            localSocket.on('LiveTvUpdated', (data: any) => {
              dispatch(getGameEventScoreAndLiveTV(true, false) as any)
            })

            localSocket.on('tournamentUpdated', (data: any) => {
              dispatch(getTournament() as any)
            })

            localSocket.on('DeleteLiveTvUpdate', (data: any) => {
              let currentTv = liveTvMatches
              let currentEventId = data.eventId
              const result = currentTv.filter(
                (item: any) => !currentEventId.includes(item.eventId),
              )
              dispatch({
                type: GET_MATCHES_LIVE_TV,
                payload: result,
              })
            })

            // GET EVENT SCORE
            localSocket.on('getEventScore', (data: any) => {
              console.log('SOCKET --- EVENT SCORE')
              let dataLocal = data.docs
              let scoreData = {
                eventId: dataLocal.eventId,
                iframeUrl: dataLocal.iframeUrl,
                score: dataLocal.score ? JSON.parse(dataLocal.score) : null,
              }
              dispatch({
                type: SAVE_SCORE,
                scoreData: scoreData,
                isLoading: false,
              })
            })

            // GET MODIFY MARKET UPDATE
            localSocket.on('getModifyMarketUpdate', (data: any) => {
              console.log('SOCKET --- MODIFY MARKET')
              dispatch({
                type: UPDATE_MODIFY_MARKET,
              })
            })

            localSocket.on('getMarketCacheUpdates', (data: any) => {
              setTimeout(() => {
                dispatch(getCacheMarket() as any)
                dispatch({
                  type: UPDATE_MODIFY_MARKET,
                })
              }, 3000)
            })

            localSocket.on('getHomeCacheUpdates', () => {
              setTimeout(() => {
                dispatch({
                  type: UPDATE_MODIFY_MARKET,
                })
                dispatch(getHomeData() as any)
              }, 3000)
            })

            localSocket.on('scoreUpdated', (data: any) => {
              dispatch(addRemoveScore() as any)
            })

            localSocket.on('scoreDeleted', () => {
              dispatch(addRemoveScore() as any)
            })

            localSocket.on('getAddMarketUpdate', (data: any) => {
              console.log('SOCKET --- ADD MARKET')
              dispatch({
                type: ADDED_MARKET,
                data: !isMarketAddedLoad,
              })
            })

            localSocket.on('getDeleteMarketUpdate', (data: any) => {
              console.log('SOCKET --- DELETE MARKET')
              dispatch({
                type: DELETED_MARKET,
                data: !isMarketDeletedload,
              })
            })

            localSocket.on('getOpenBetCasinoUpdates', (data: any) => {
              console.log('SOCKET --- CASINO BET')
              dispatch(
                getProfile(navigate, 'user', currentDomain, false) as any,
              )
            })
            localSocket.on('getOpenBetCasinoResultUpdates', (data: any) => {
              console.log('SOCKET --- CASINO RESULT')
              dispatch(
                getProfile(navigate, 'user', currentDomain, false) as any,
              )
            })
          }

          // ADD WITHDRAW REQUEST
          localSocket.on('getWithdrawUpdates', () => {
            dispatch({
              type: WITHDRAW_ADDED,
              payload: true,
            })
          })

          // ADD USER REQUEST
          localSocket.on('getUserCreated', () => {
            dispatch({
              type: USER_ADDED,
              payload: true,
            })
          })

          // ADD DEPOSIT REQUEST
          localSocket.on('getDepositUpdates', () => {
            dispatch({
              type: DEPOSIT_ADDED,
              payload: true,
            })
          })

          localSocket.on('getOpenBetUpdates', (data: any) => {
            dispatch(getProfile(navigate, 'user', currentDomain, false) as any)
            dispatch(updateOpenBet(data.docs, 'user') as any)
          })

          localSocket.on('reconnect_attempt', (attemptNumber: number) => {
            console.log(`Reconnection attempt #${attemptNumber}`)
          })

          localSocket.on('reconnect_error', (error: any) => {
            console.error('Reconnection error:', error)
          })

          localSocket.on('reconnect_failed', () => {
            console.error('Reconnection failed, will not try further')
          })

          localSocket.on('getModifyMarketUpdate', () => {
            dispatch({
              type: UPDATE_MODIFY_MARKET,
              payload: null,
            })
          })
        }
      })
    }

    return () => {
      if (socket) {
        dispatch({
          type: APP_CONFIGURATION_SOCKET_BLOCKED,
          payload: true,
        })
        socket.disconnect()
      }
    }
  }, [currentDomain, isLogin, deletedMarketList])

  return <></>
}
