import React, { useEffect, useState } from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { MenuItem, Modal, Skeleton } from '@mui/material'
import { initializeStrings } from '../../../utils/strings'
import Marquee from 'react-fast-marquee'
import Button from '@mui/material/Button'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import IconButton from '@mui/material/IconButton'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import MenuIcon from '@mui/icons-material/Menu'
import { IoMdSunny, IoMdMoon } from 'react-icons/io'
import { MobileNavBarSports } from '../../../component/navbar/MobileNavBarSports'
import { useNavigate } from 'react-router-dom'
import '../../../component/style.css'
import {
  ForgetPassword,
  Login,
  Register,
  TermsAndConditions,
} from '../../../component'
import NavbarProfileMenus from '../../../component/menu/NavbarProfileMenu'
import {
  APP_CONFIGURATION_SEARCH_CONTENT,
  APP_CONFIGURATION_SEARCH_CONTENT_LOADING,
  APP_CONFIGURATION_SEARCH_CONTENT_MARKET,
  APP_GLOBAL_SEARCH_CONTENT,
  changePassword,
  LANGUAGE,
} from '../../../redux'
import { useDispatch, useSelector } from 'react-redux'
import { ChangePassword } from '../../../component/changePassword/ChangePassword'
import { toast } from 'react-toastify'
import { emptyFunction, get2Decimal, getThemeAssetUrl } from '../../../utils'
import {
  COMMON_WEBSITE,
  DEFAULT_LAZER7_WEBSITE_NAME,
  DEFAULT_TEST_WEBSITE_NAME,
  DEFAULT_WEBSITE_NAME,
  HEADERS,
  IS_LOCAL_FUTURE9,
  URLType,
  makeGetHttpCallAsync,
} from '../../../api'
import CampaignIcon from '@mui/icons-material/Campaign'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ICurrentDomain } from '../../../types/interfaces/currentDomain'
import { IThemeConfiguration } from '../../../types/interfaces/themeConfiguration'
import {
  IChangePasswordPayload,
  IUserData,
  IUserLogin,
  IUserSignUp,
} from '../../../types/interfaces/login'
import ShowDateTime from './ShowDateTime'
import { IAppConfiguration } from '../../../types/interfaces/appConfiguration'
import { Info } from '@mui/icons-material'
import { Rules } from '../../../component/Content/Rules'
import { isMobileScreensix } from '../../../utils/internalUtils'
import {
  BET_SLIP_STAKE,
  UPDATE_CURRENT_BETSLIP,
} from '../../../redux/actions/userAction/betSlipAction'
import { SAVE_SEARCH_MARKETIDS } from '../../../redux/actions/marketStatusAction'
import LoginSharpIcon from '@mui/icons-material/LoginSharp'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
export interface IHeader {
  demoLogin: boolean
  isDarkMode?: boolean
  className?: string
  lang?: string
  openLoginScreen: boolean
  currentDomain: ICurrentDomain
  isLogin?: boolean
  matchBet: any
  matchBetPagination: any
  appThemeConfiguration?: IThemeConfiguration
  updateThemeToggle?: (isDarkMode?: boolean) => void
  languageUpdate?: (lang: string) => void
  updateOpenLoginScreen: (openLoginScreen: boolean) => void
  loginUser?: (
    userLoggedIn: IUserLogin,
    navigate: any,
    closeModal: (isLogin: boolean) => void,
    setIsChangePassword: (isChangedPassword: boolean, docs: any) => void,
    currentDomain: ICurrentDomain,
  ) => void
  changePassword?: (
    data: IChangePasswordPayload,
    currentDomain: ICurrentDomain,
    closeModal: (isC: boolean) => void,
    setIsChangePassword?: (isChangedPassword: boolean) => void,
  ) => void
  signUpUser?: (
    userSignUpLoader: IUserSignUp,
    navigate: any,
    setIsRegister: (isOpen: boolean) => void,
  ) => void
  logoutUser?: (navigate: any, userType: String) => void
  userData?: IUserData
}

// Header
export const Header: React.FC<
  IHeader & {
    appConfiguration: IAppConfiguration
  }
> = (
  props: IHeader & {
    appConfiguration: IAppConfiguration
  },
) => {
  const [openRules, setOpenRules] = useState(false)
  const [isLoginScreen, setIsLoginScren] = useState<boolean>(
    props.openLoginScreen,
  )

  const [isRegister, setIsRegister] = useState<boolean>(false)
  const [isForgetPassword, setIsForgetPassword] = useState<boolean>(false)
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  const [isShow, seIIsShow] = useState(false)
  const isLargeScreen = useMediaQuery('(min-width:1360px)')
  const isSmallestScreen = useMediaQuery('(max-width:450px)')

  const dispatch = useDispatch()

  const [isTermsAndConditions, setIsTermsAndConditions] =
    useState<boolean>(false)

  const navigate = useNavigate()

  const { enable } = useSelector((state: any) => state.appThemeConfiguration)

  const [reRender, setReRender] = useState(false)
  const [mobileNavBarSports, setMobileNavBarSports] = useState(false)

  const handleClick = () => {
    setMobileNavBarSports(true)
  }

  const { languages, headerMarquee } = useSelector(
    (state: any) => state.appCommonTheme,
  )

  useEffect(() => {
    setIsLoginScren(props.openLoginScreen)
  }, [props.openLoginScreen])

  const openLogin = () => {
    setIsLoginScren(true)
    setIsRegister(false)
    setIsForgetPassword(false)
  }

  const openRegister = () => {
    setIsRegister(true)
    setIsLoginScren(false)
    setIsForgetPassword(false)
  }

  const depositPage = () => {
    if (props.demoLogin) {
      return toast.error(_getLocalString('LOC_DEMO_ACCOUNT_ERROR_MESSAGE'))
    }
    navigate('/deposit')
  }

  const handleLogoClick = () => {
    dispatch({
      type: SAVE_SEARCH_MARKETIDS,
      data: '',
    })

    dispatch({
      type: APP_GLOBAL_SEARCH_CONTENT,
      payload: [],
    })

    dispatch({
      type: UPDATE_CURRENT_BETSLIP,
      currentBet: {},
    })
    dispatch({
      type: BET_SLIP_STAKE,
      betSlipStake: 0,
    })
    navigate('/')
  }

  const widrawalPage = () => {
    if (props.demoLogin) {
      return toast.error(_getLocalString('LOC_DEMO_ACCOUNT_ERROR_MESSAGE'))
    }
    navigate('/withdraw')
  }

  useEffect(() => {
    if (localStorage.getItem('changePassword')) {
      setIsChangePassword(true)
    }
  }, [])

  const handleHelpClick = () => {
    toast.info(_getLocalString('LOC_COMMING_SOON'))
  }

  function convertToEventTypeArray(inputData: any) {
    const eventTypeArray: any[] = []

    inputData.forEach((item: any) => {
      const {
        eventType,
        _id,
        id,
        type,
        marketName,
        totalMatched,
        manual,
        top,
        bottom,
        active,
        cashout,
        createdAt,
        updatedAt,
      } = item

      const marketId = {
        _id: _id,
        id: id,
        name: marketName,
        type: type,
        marketName: marketName,
        totalMatched: totalMatched,
        manual: manual,
        top: top,
        bottom: bottom,
        eventType: eventType,
        event: item.event,
        active: active,
        cashout: cashout,
        createdAt: createdAt,
        updatedAt: updatedAt,
      }

      const eventTypeObj = eventTypeArray.find(
        (e) => e.eventTypeId === eventType.id,
      )

      if (eventTypeObj) {
        eventTypeObj.events.push({
          _id: item._id,
          eventId: item.event.id,
          marketId: marketId,
          createdAt: createdAt,
          delaySecond: 0,
          eventTypeId: eventType.id,
          marketActive: true,
          max: 100000,
          min: 100,
          odds: [],
          openDate: item.event.openDate,
          status: active ? 'active' : 'inactive',
          teams: item.teams,
          type: type,
          updatedAt: updatedAt,
        })
      } else {
        const newEventTypeObj = {
          eventTypeId: eventType.id,
          events: [
            {
              _id: item._id,
              eventId: item.event.id,
              marketId: marketId,
              createdAt: createdAt,
              delaySecond: 0,
              eventTypeId: eventType.id,
              marketActive: true,
              max: 100000,
              min: 100,
              odds: [],
              openDate: item.event.openDate,
              status: active ? 'active' : 'inactive',
              teams: item.teams,
              type: type,
              updatedAt: updatedAt,
            },
          ],
        }

        eventTypeArray.push(newEventTypeObj)
      }
    })

    return eventTypeArray
  }

  const onSearchKeyPress = (event: any) => {
    if (search) {
    } else {
      seIIsShow(!isShow)
    }

    if (!search) {
      navigate('/')
      return
    }

    dispatch({
      type: APP_CONFIGURATION_SEARCH_CONTENT,
      payload: event.target.value,
    })

    dispatch({
      type: APP_CONFIGURATION_SEARCH_CONTENT_LOADING,
      payload: true,
    })

    makeGetHttpCallAsync(
      dispatch,
      'api/common/game/market',
      `search=${search}&active=true`,
      URLType.master,
      false,
      false,
      '',
    ).then((response: any) => {
      if (!response.docs.length) {
        toast.error(_getLocalString('LOC_NO_SEARCH_FOUND'))
        setSearch('')

        dispatch({
          type: APP_CONFIGURATION_SEARCH_CONTENT_MARKET,
          payload: [],
        })

        dispatch({
          type: APP_CONFIGURATION_SEARCH_CONTENT_LOADING,
          payload: false,
        })

        navigate('/')
        return
      }

      dispatch({
        type: APP_CONFIGURATION_SEARCH_CONTENT_MARKET,
        payload: convertToEventTypeArray(response.docs),
      })

      dispatch({
        type: APP_CONFIGURATION_SEARCH_CONTENT_LOADING,
        payload: false,
      })

      navigate('/search')
    })
  }

  useEffect(() => {
    let localLanguage = localStorage.getItem(HEADERS.language)
    localLanguage &&
      dispatch({
        type: LANGUAGE,
        lang: localLanguage,
      })
    initializeStrings(localLanguage ? localLanguage : props.lang).then(() =>
      setReRender(!reRender),
    )
  }, [props.lang])

  const isSmallScreen = useMediaQuery('(max-width:340px)')
  const isTabletScreen = useMediaQuery('(max-width:900)')
  const buttonPadding = isSmallScreen ? '4px 8px' : '4px 5px'
  const isSmall = useMediaQuery('(max-width:340px)')

  const currentDomain = window.location.hostname
  let domainName = currentDomain.split('.')[0]

  if (domainName === 'www') {
    domainName = currentDomain.split('.')[1]
  }

  const isFuture9 =
    domainName === DEFAULT_WEBSITE_NAME ||
    domainName === DEFAULT_LAZER7_WEBSITE_NAME ||
    domainName === DEFAULT_TEST_WEBSITE_NAME ||
    domainName === COMMON_WEBSITE ||
    (domainName === 'localhost' && IS_LOCAL_FUTURE9)

  function requestNotificationPermission() {
    if (Notification.permission === 'default' && props.isLogin) {
      const userAgrees = confirm(
        "We'd like to send you notifications for important updates. Do you allow?",
      )

      if (userAgrees) {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted')
          } else {
            console.error('Notification permission denied')
          }
        })
      }
    } else if (Notification.permission === 'granted') {
      // The user has already granted permission
      console.log('Notification permission already granted')
    } else {
      // The user has already denied permission
      console.error('Notification permission already denied')
    }
  }

  return (
    <div className=''>
      {props.appConfiguration.siteLoader && !isMobileScreensix() ? (
        <div className='fixed z-50 w-full p-0'>
          <Skeleton variant='rectangular' width={'100%'} height={100} />{' '}
        </div>
      ) : (
        <>
          <div
            onClick={requestNotificationPermission}
            className='fixed z-50 items-center justify-between hidden w-full gap-3 py-4 md:px-4 lg:px-16 xl:px-16 lg:flex header_gradient md:h-16'
          >
            <div
              className='cursor-pointer'
              style={{
                width:
                  !isSmallScreen && isTabletScreen
                    ? '20%'
                    : isFuture9
                      ? '14%'
                      : '10%',
              }}
            >
              {props.appThemeConfiguration!.headerLogo ? (
                <img
                  alt='Theme Header Logo'
                  className='cursor-pointer'
                  onClick={handleLogoClick}
                  src={
                    props.appThemeConfiguration!.headerLogo.startsWith(
                      'https://',
                    )
                      ? props.appThemeConfiguration!.headerLogo
                      : getThemeAssetUrl(
                          props.appThemeConfiguration!.headerLogo,
                        )
                  }
                />
              ) : (
                props.appThemeConfiguration!.logoText
              )}
            </div>
            <div className='flex gap-10'>
              {props.isLogin && (
                <div className='relative text-xs !leading-none items-center !min-w-max flex !font-primary'>
                  <ShowDateTime userData={props.userData} />
                </div>
              )}

              <div className='flex items-center justify-between gap-1'>
                <Marquee pauseOnHover={true} gradient={false} speed={50}>
                  <div
                    className={`hidden md:flex mr-2 font-primary text-base font-medium leading-[30px] tracking-normal text-left ${props.userData?.userType === 'user' ? 'lg:text-sm xl:text-base' : ''}`}
                  >
                    {headerMarquee}
                  </div>
                </Marquee>
              </div>
            </div>

            <div
              className={`flex items-center  ${
                props.userData?.userType !== 'user' && props.isLogin
                  ? 'gap-2'
                  : 'gap-2'
              }`}
            >
              <div>
                <CampaignIcon
                  className='hidden md:flex w-3 h-[10px]'
                  style={{ transform: 'scaleX(-1)', fontSize: '26px' }}
                />
              </div>
              {props.isLogin && props.userData?.userType !== 'user' ? (
                <>
                  {enable?.isNightMode && (
                    <LightDarkMode
                      matchBetPagination={props.matchBetPagination}
                      demoLogin={props.demoLogin}
                      updateOpenLoginScreen={props.updateOpenLoginScreen}
                      openLoginScreen={props.openLoginScreen}
                      key='nightMode1'
                      currentDomain={props.currentDomain}
                      matchBet={props.matchBet}
                      isDarkMode={props.isDarkMode}
                      updateThemeToggle={props.updateThemeToggle}
                      className='!text-red-500'
                    />
                  )}
                  <Button
                    onClick={handleHelpClick}
                    className='!text-black !bg-white !rounded-full !text-xs !px-4 !py-2'
                    startIcon={
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='18'
                        viewBox='0 0 14 18'
                        fill='none'
                      >
                        <path
                          d='M13.8899 14.7162C13.4846 13.5174 11.8691 12.767 10.7173 12.264C10.2661 12.0676 9.01687 11.7344 8.86673 11.1698C8.81299 10.9664 8.82024 10.7746 8.86431 10.5908C8.79488 10.604 8.72444 10.6118 8.65159 10.6118H7.9011C7.30196 10.6118 6.81511 10.1274 6.81511 9.5324C6.81511 8.9378 7.30216 8.4544 7.9011 8.4544H8.65159C8.89953 8.4544 9.1346 8.5378 9.32419 8.6866C9.60212 8.6498 9.8702 8.5908 10.1202 8.5134C10.4484 7.83 10.7044 7.0128 10.7618 6.3094C11.0067 3.2998 9.1501 1.539 6.48807 1.8434C4.55258 2.0648 3.39636 3.499 3.27138 5.3454C3.14499 7.2274 3.84718 8.6174 4.59304 9.637C4.91968 10.0828 5.26282 10.3694 5.21009 10.9066C5.14891 11.5418 4.46544 11.7188 3.97659 11.914C3.39737 12.1452 2.77347 12.496 2.47883 12.6582C1.46389 13.2152 0.349933 13.886 0.0995689 14.8036C-0.454894 16.8372 1.4176 17.4532 2.96346 17.7376C4.29014 17.9808 5.78609 18 7.01657 18C9.24228 18 13.2445 17.9114 13.8899 16.2488C14.0735 15.777 13.9948 15.0252 13.8899 14.7162Z'
                          fill='var(--theme-color)'
                        />
                        <path
                          d='M9.12655 9.2286C9.02472 9.0744 8.85063 8.972 8.65199 8.972H7.9015C7.58834 8.972 7.33577 9.2234 7.33577 9.533C7.33577 9.8438 7.58834 10.0956 7.9015 10.0956H8.65199C8.87116 10.0956 9.05712 9.9714 9.1509 9.7918C10.1972 9.71 11.1073 9.3924 11.7461 8.9234C11.8928 9.0174 12.0663 9.0724 12.2535 9.0724H12.3006C12.8222 9.0724 13.2441 8.6528 13.2441 8.1338V6.2602C13.2441 5.887 13.0239 5.565 12.7063 5.4154C12.5678 2.4068 10.0616 0 7.00007 0C3.93855 0 1.43189 2.4068 1.29403 5.4154C0.975842 5.5652 0.755868 5.887 0.755868 6.2602V8.1338C0.755868 8.6528 1.17811 9.0724 1.69856 9.0724H1.74625C2.26711 9.0724 2.68955 8.6528 2.68955 8.1338V6.2602C2.68955 5.8924 2.47561 5.5752 2.16467 5.4218C2.29931 2.8868 4.41613 0.8662 7.00007 0.8662C9.583 0.8662 11.7008 2.8868 11.8349 5.4218C11.5243 5.5754 11.3106 5.8924 11.3106 6.2602V8.1338C11.3106 8.2582 11.3349 8.374 11.3778 8.4822C10.828 8.8732 10.0276 9.1518 9.12655 9.2286Z'
                          fill='var(--theme-color)'
                        />
                      </svg>
                    }
                  >
                    {_getLocalString('LOC_HELP')}
                  </Button>
                </>
              ) : (
                <IconButton
                  className='!text-black !bg-white !rounded-full !text-xs !px-3'
                  onClick={handleHelpClick}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='14'
                    height='18'
                    viewBox='0 0 14 18'
                    fill='none'
                  >
                    <path
                      d='M13.8899 14.7162C13.4846 13.5174 11.8691 12.767 10.7173 12.264C10.2661 12.0676 9.01687 11.7344 8.86673 11.1698C8.81299 10.9664 8.82024 10.7746 8.86431 10.5908C8.79488 10.604 8.72444 10.6118 8.65159 10.6118H7.9011C7.30196 10.6118 6.81511 10.1274 6.81511 9.5324C6.81511 8.9378 7.30216 8.4544 7.9011 8.4544H8.65159C8.89953 8.4544 9.1346 8.5378 9.32419 8.6866C9.60212 8.6498 9.8702 8.5908 10.1202 8.5134C10.4484 7.83 10.7044 7.0128 10.7618 6.3094C11.0067 3.2998 9.1501 1.539 6.48807 1.8434C4.55258 2.0648 3.39636 3.499 3.27138 5.3454C3.14499 7.2274 3.84718 8.6174 4.59304 9.637C4.91968 10.0828 5.26282 10.3694 5.21009 10.9066C5.14891 11.5418 4.46544 11.7188 3.97659 11.914C3.39737 12.1452 2.77347 12.496 2.47883 12.6582C1.46389 13.2152 0.349933 13.886 0.0995689 14.8036C-0.454894 16.8372 1.4176 17.4532 2.96346 17.7376C4.29014 17.9808 5.78609 18 7.01657 18C9.24228 18 13.2445 17.9114 13.8899 16.2488C14.0735 15.777 13.9948 15.0252 13.8899 14.7162Z'
                      fill='var(--theme-color)'
                    />
                    <path
                      d='M9.12655 9.2286C9.02472 9.0744 8.85063 8.972 8.65199 8.972H7.9015C7.58834 8.972 7.33577 9.2234 7.33577 9.533C7.33577 9.8438 7.58834 10.0956 7.9015 10.0956H8.65199C8.87116 10.0956 9.05712 9.9714 9.1509 9.7918C10.1972 9.71 11.1073 9.3924 11.7461 8.9234C11.8928 9.0174 12.0663 9.0724 12.2535 9.0724H12.3006C12.8222 9.0724 13.2441 8.6528 13.2441 8.1338V6.2602C13.2441 5.887 13.0239 5.565 12.7063 5.4154C12.5678 2.4068 10.0616 0 7.00007 0C3.93855 0 1.43189 2.4068 1.29403 5.4154C0.975842 5.5652 0.755868 5.887 0.755868 6.2602V8.1338C0.755868 8.6528 1.17811 9.0724 1.69856 9.0724H1.74625C2.26711 9.0724 2.68955 8.6528 2.68955 8.1338V6.2602C2.68955 5.8924 2.47561 5.5752 2.16467 5.4218C2.29931 2.8868 4.41613 0.8662 7.00007 0.8662C9.583 0.8662 11.7008 2.8868 11.8349 5.4218C11.5243 5.5754 11.3106 5.8924 11.3106 6.2602V8.1338C11.3106 8.2582 11.3349 8.374 11.3778 8.4822C10.828 8.8732 10.0276 9.1518 9.12655 9.2286Z'
                      fill='var(--theme-color)'
                    />
                  </svg>
                </IconButton>
              )}

              {(props.userData?.userType === 'user' || !props.isLogin) && (
                <div
                  className={'cursor-pointer'}
                  onClick={() => setOpenRules(true)}
                  style={{
                    border: 'solid 0.5px',
                    borderRadius: '1rem',
                    padding: '3px 10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div className='mr-0.5'>
                    <Info />
                  </div>
                  <div>{_getLocalString('LOC_RULES')}</div>
                </div>
              )}

              {props.isLogin ? (
                <>
                  {props.userData?.userType == 'user' && (
                    <>
                      {enable?.isNightMode && (
                        <LightDarkMode
                          matchBetPagination={props.matchBetPagination}
                          demoLogin={props.demoLogin}
                          updateOpenLoginScreen={props.updateOpenLoginScreen}
                          openLoginScreen={props.openLoginScreen}
                          currentDomain={props.currentDomain}
                          matchBet={props.matchBet}
                          isDarkMode={props.isDarkMode}
                          updateThemeToggle={props.updateThemeToggle}
                          className='!text-red-500'
                        />
                      )}
                      <div className='flex !min-w-max gap-2'>
                        {enable?.depositButton && (
                          <Button
                            variant='contained'
                            disableElevation
                            style={{ fontWeight: '700' }}
                            className='first-element !text-black !bg-white !rounded-full !capitalize !font-primary lg:!text-xs xl:!text-sm lg:!px-2 xl:!px-4 !py-2'
                            onClick={depositPage}
                            startIcon={
                              <AccountBalanceWalletOutlinedIcon
                                fontSize='small'
                                className='!text-primary !text-xl'
                              />
                            }
                          >
                            {_getLocalString('LOC_DEPOSIT')}
                          </Button>
                        )}
                        {enable?.withdrawButton && (
                          <Button
                            variant='contained'
                            disableElevation
                            onClick={widrawalPage}
                            style={{ fontWeight: '600' }}
                            className='second-element !text-black !bg-white !rounded-full !capitalize !font-primary lg:!text-xs xl:!text-sm font-bold lg:!px-2 xl:!px-4 !py-2'
                            startIcon={
                              <AccountBalanceWalletOutlinedIcon
                                fontSize='small'
                                className='!text-primary !text-xl'
                              />
                            }
                          >
                            {_getLocalString('LOC_WITHDRAW')}
                          </Button>
                        )}
                      </div>

                      <div className='!min-w-max'>
                        <div className='md:text-[10px] lg:text-[12px] xl:text-base font-medium leading-[19px] tracking-normal text-left'>
                          {_getLocalString('LOC_POINTS')}:{' '}
                          {get2Decimal(props.userData?.availablePts)}
                        </div>
                        <div className='md:text-[10px] lg:text-[12px] xl:text-base font-medium leading-[19px] tracking-normal text-left'>
                          {_getLocalString('LOC_EXPOSURE_SMALL_ONLY')}:{' '}
                          {get2Decimal(props.userData?.exposure)}
                        </div>
                      </div>
                    </>
                  )}
                  <div className=''>
                    <NavbarProfileMenus
                      logoutUser={props.logoutUser}
                      {...props}
                      changePassword={props.changePassword}
                      matchBet={props.matchBet}
                      logoutFunc={props.logoutUser}
                      userType={props.userData?.userType || ''}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className='flex gap-3 !min-w-max items-center justify-center'>
                    {enable?.isNightMode && (
                      <LightDarkMode
                        matchBetPagination={props.matchBetPagination}
                        demoLogin={props.demoLogin}
                        updateOpenLoginScreen={props.updateOpenLoginScreen}
                        openLoginScreen={props.openLoginScreen}
                        currentDomain={props.currentDomain}
                        matchBet={props.matchBet}
                        isDarkMode={props.isDarkMode}
                        updateThemeToggle={props.updateThemeToggle}
                        className='!text-red-500'
                      />
                    )}
                    {enable?.isLanguage && (
                      <DropDown
                        item={languages}
                        selectedValue={props.lang}
                        update={props.languageUpdate}
                      />
                    )}
                  </div>
                  <div className='flex justify-center col-span-2 gap-3'>
                    <div
                      style={{ minWidth: '7rem' }}
                      className={`rounded-md bg-gradient-to-l from-[color:var(--button-first-bg)] to-[color:var(--button-second-bg)] ${props.currentDomain.domainName === 'winwave247.com' ? 'text-black bold' : 'white'}  text-sm px-4 py-2 cursor-pointer flex gap-2 items-center justify-center border border-[--theme-color] h-[38px]'`}
                      onClick={openLogin}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='15'
                        height='19'
                        viewBox='0 0 12 16'
                        fill='none'
                      >
                        <path
                          d='M12 16H10.6667V12.638C10.6661 12.1153 10.4583 11.6142 10.0887 11.2446C9.71911 10.8751 9.218 10.6672 8.69533 10.6667H3.30467C2.782 10.6672 2.28089 10.8751 1.91131 11.2446C1.54173 11.6142 1.33386 12.1153 1.33333 12.638V16H0V12.638C0.00105855 11.7619 0.349568 10.9219 0.969083 10.3024C1.5886 9.6829 2.42854 9.33439 3.30467 9.33333H8.69533C9.57146 9.33439 10.4114 9.6829 11.0309 10.3024C11.6504 10.9219 11.9989 11.7619 12 12.638V16Z'
                          fill={
                            props.currentDomain.domainName === 'winwave247.com'
                              ? 'black'
                              : 'white'
                          }
                        />
                        <path
                          d='M6 8C5.20887 8 4.43551 7.76541 3.77772 7.32588C3.11992 6.88635 2.60723 6.26164 2.30448 5.53074C2.00173 4.79983 1.92252 3.99556 2.07686 3.21964C2.2312 2.44372 2.61216 1.73098 3.17157 1.17157C3.73098 0.612165 4.44371 0.231202 5.21964 0.0768607C5.99556 -0.0774802 6.79983 0.00173316 7.53073 0.304484C8.26164 0.607234 8.88635 1.11992 9.32588 1.77772C9.7654 2.43552 10 3.20888 10 4C9.99894 5.06054 9.57717 6.07734 8.82725 6.82726C8.07734 7.57718 7.06054 7.99894 6 8ZM6 1.33334C5.47258 1.33334 4.95701 1.48973 4.51848 1.78275C4.07995 2.07577 3.73815 2.49224 3.53632 2.97951C3.33449 3.46678 3.28168 4.00296 3.38457 4.52024C3.48747 5.03753 3.74144 5.51268 4.11438 5.88562C4.48732 6.25856 4.96247 6.51254 5.47976 6.61543C5.99704 6.71832 6.53322 6.66551 7.02049 6.46368C7.50776 6.26185 7.92423 5.92005 8.21725 5.48152C8.51027 5.04299 8.66666 4.52742 8.66666 4C8.66666 3.29276 8.38571 2.61448 7.88562 2.11438C7.38552 1.61429 6.70724 1.33334 6 1.33334Z'
                          fill={
                            props.currentDomain.domainName === 'winwave247.com'
                              ? 'black'
                              : 'white'
                          }
                        />
                      </svg>
                      {_getLocalString('LOC_LOGIN_SMALL_CASE')}
                    </div>
                    {enable?.isRegister &&
                      props.currentDomain.type !== 'b2b' && (
                        <div
                          className={`rounded-md bg-gradient-to-l from-[color:var(--button-first-bg)] to-[color:var(--button-second-bg)] ${props.currentDomain.domainName === 'winwave247.com' ? 'text-black bold' : 'white'}  text-sm px-4 py-2 cursor-pointer flex gap-2 items-center justify-center border border-[--theme-color] h-[38px]'`}
                          onClick={openRegister}
                        >
                          <LoginSharpIcon
                            className={`${
                              props.currentDomain.domainName ===
                              'winwave247.com'
                                ? 'black'
                                : 'white'
                            } !w-[23px] font-thin rotate-180`}
                          />
                          {_getLocalString('LOC_REGISTER')}
                        </div>
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {!props.appConfiguration.siteLoader && (
        <div className='!bg-[linear-gradient(94.28deg,var(--nav-bar-popup-1)_3 .45%,var(--nav-bar-popup-2)_28.47%,var(--nav-bar-icon-3)_61.86%,var(--nav-bar-icon-4)_98.65%)] items-center justify-between w-full gap-3 lg:hidden header_gradient'>
          <div className=' w-full flex-wrap flex items-center justify-between drop-shadow'>
            <ShowDateTime userData={props.userData} />
            {(props.userData?.userType === 'user' || !props.isLogin) && (
              <div
                className='cursor-pointer rounded-full p-2 xs:rounded-none  sm:rounded-xl sm:border-solid sm:border-[0.5px] sm:border-color'
                onClick={() => setOpenRules(true)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '8px',
                  marginTop: '4px',
                }}
              >
                <div className='mr-0 sm:mr-1 flex justify-center items-center'>
                  <Info />
                </div>
                <div className='hidden  sm:block'>
                  {_getLocalString('LOC_RULES')}
                </div>
              </div>
            )}

            {/* {props.currentDomain &&
                props.currentDomain.type &&
                props.currentDomain.type === 'b2c' &&
                props.appThemeConfiguration?.registration?.whatsappNumber &&
                props.isLogin && (
                  <WhatsappIconTelegram
                    whatsappLink={
                      props.appThemeConfiguration?.registration?.whatsappNumber
                    }
                  />
                )} */}

            <div className='flex items-center w-full py-1 '>
              <Marquee pauseOnHover={true} gradient={false} speed={50}>
                <div className='!font-primary !text-sm font-medium text-left '>
                  {headerMarquee}
                </div>
              </Marquee>
              <CampaignIcon
                className='hidden md:flex w-3 h-[10px]'
                style={{ transform: 'scaleX(-1)', fontSize: '20px' }}
              />

              {/* Buttons */}
              {props.userData?.userType === 'user' && (
                <div className='flex !min-w-max gap-2 ml-1 justify-end'>
                  {enable?.depositButton && (
                    <Button
                      variant='contained'
                      disableElevation
                      style={{
                        fontWeight: '700',
                        width: 'auto',
                        padding: buttonPadding,
                        minWidth: 'unset',
                        outline: 'none',
                        boxShadow: 'none',
                        backgroundColor: '#0aaf1b',
                        color: 'white',
                      }}
                      className='!rounded-md !capitalize !font-primary !text-[8px] font-bold'
                      onClick={depositPage}
                      startIcon={
                        !isSmallScreen && (
                          <AccountBalanceWalletOutlinedIcon
                            style={{ fontSize: '16px' }}
                            className='text-white'
                          />
                        )
                      }
                    >
                      {_getLocalString('LOC_DEPOSIT')}
                    </Button>
                  )}
                  {enable?.withdrawButton && (
                    <Button
                      variant='contained'
                      disableElevation
                      onClick={widrawalPage}
                      style={{
                        fontWeight: '700',
                        width: 'auto',
                        padding: buttonPadding,
                        minWidth: 'unset',
                        outline: 'none',
                        boxShadow: 'none',
                        backgroundColor: '#FF0B0B',
                        color: 'white',
                        marginRight: '6px',
                      }}
                      className=' !rounded-md !capitalize !font-primary !text-[8px] font-bold'
                      startIcon={
                        !isSmallScreen && (
                          <AccountBalanceWalletOutlinedIcon
                            style={{ fontSize: '16px' }}
                            className='text-white'
                          />
                        )
                      }
                    >
                      {_getLocalString('LOC_WITHDRAW')}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='flex flex-wrap py-4 px-3 gap-3 relative border-t border-[#c9c9c954] items-center'>
            {/* ---------------Div 1------------------- */}
            <div className='flex items-center  gap-5 w-full justify-between'>
              <div className='flex items-center gap-1 sm:gap-5 '>
                <div onClick={handleClick}>
                  {mobileNavBarSports ? (
                    <MenuOpenIcon fontSize='large' />
                  ) : (
                    <MenuIcon fontSize='large' />
                  )}
                </div>
                <div
                  className='bold cursor-pointer shrink-0 text-3xl'
                  onClick={handleLogoClick}
                >
                  {props.appThemeConfiguration!.headerLogo ? (
                    <img
                      alt='Mobile Logo'
                      id='logo-mobile-image'
                      className={`${isSmallestScreen ? '!w-[100px]' : '!w-[150px] '}  h-auto`}
                      onClick={handleLogoClick}
                      src={
                        props.appThemeConfiguration!.headerLogo.startsWith(
                          'https://',
                        )
                          ? props.appThemeConfiguration!.headerLogo
                          : getThemeAssetUrl(
                              props.appThemeConfiguration!.headerLogo,
                            )
                      }
                    />
                  ) : (
                    props.appThemeConfiguration!.logoText
                  )}
                </div>
              </div>
              {mobileNavBarSports && (
                <MobileNavBarSports
                  currentDomain={props.currentDomain}
                  isDarkMode={props.isDarkMode}
                  isLogin={props.isLogin}
                  userData={props.userData}
                  navBarSportsOpen={(navBarOpen: boolean) =>
                    setMobileNavBarSports(navBarOpen)
                  }
                />
              )}
              <div className='flex items-center gap-5'>
                {/* ----------------Div 2----------------- */}
                <div className='flex items-center gap-2 '>
                  {props.isLogin ? (
                    <>
                      {!isMobileScreensix() &&
                        props.userData?.userType === 'user' &&
                        enable?.isNightMode && (
                          <LightDarkMode2
                            matchBetPagination={props.matchBetPagination}
                            demoLogin={props.demoLogin}
                            updateOpenLoginScreen={props.updateOpenLoginScreen}
                            openLoginScreen={props.openLoginScreen}
                            key='nightMode2'
                            currentDomain={props.currentDomain}
                            matchBet={props.matchBet}
                            isDarkMode={props.isDarkMode}
                            updateThemeToggle={props.updateThemeToggle}
                            className='!text-red-500'
                          />
                        )}

                      {props.userData?.userType !== 'user' &&
                        enable?.isNightMode && (
                          <LightDarkMode2
                            matchBetPagination={props.matchBetPagination}
                            demoLogin={props.demoLogin}
                            updateOpenLoginScreen={props.updateOpenLoginScreen}
                            openLoginScreen={props.openLoginScreen}
                            key='nightMode2'
                            currentDomain={props.currentDomain}
                            matchBet={props.matchBet}
                            isDarkMode={props.isDarkMode}
                            updateThemeToggle={props.updateThemeToggle}
                            className='!text-red-500'
                          />
                        )}

                      {!isSmall && props.userData?.userType === 'user' && (
                        <IconButton
                          className='!text-black !bg-white !rounded-full !text-xs !px-3'
                          onClick={handleHelpClick}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='10'
                            height='14'
                            viewBox='0 0 14 18'
                            fill='none'
                          >
                            <path
                              d='M13.8899 14.7162C13.4846 13.5174 11.8691 12.767 10.7173 12.264C10.2661 12.0676 9.01687 11.7344 8.86673 11.1698C8.81299 10.9664 8.82024 10.7746 8.86431 10.5908C8.79488 10.604 8.72444 10.6118 8.65159 10.6118H7.9011C7.30196 10.6118 6.81511 10.1274 6.81511 9.5324C6.81511 8.9378 7.30216 8.4544 7.9011 8.4544H8.65159C8.89953 8.4544 9.1346 8.5378 9.32419 8.6866C9.60212 8.6498 9.8702 8.5908 10.1202 8.5134C10.4484 7.83 10.7044 7.0128 10.7618 6.3094C11.0067 3.2998 9.1501 1.539 6.48807 1.8434C4.55258 2.0648 3.39636 3.499 3.27138 5.3454C3.14499 7.2274 3.84718 8.6174 4.59304 9.637C4.91968 10.0828 5.26282 10.3694 5.21009 10.9066C5.14891 11.5418 4.46544 11.7188 3.97659 11.914C3.39737 12.1452 2.77347 12.496 2.47883 12.6582C1.46389 13.2152 0.349933 13.886 0.0995689 14.8036C-0.454894 16.8372 1.4176 17.4532 2.96346 17.7376C4.29014 17.9808 5.78609 18 7.01657 18C9.24228 18 13.2445 17.9114 13.8899 16.2488C14.0735 15.777 13.9948 15.0252 13.8899 14.7162Z'
                              fill='#E33343'
                            />
                            <path
                              d='M9.12655 9.2286C9.02472 9.0744 8.85063 8.972 8.65199 8.972H7.9015C7.58834 8.972 7.33577 9.2234 7.33577 9.533C7.33577 9.8438 7.58834 10.0956 7.9015 10.0956H8.65199C8.87116 10.0956 9.05712 9.9714 9.1509 9.7918C10.1972 9.71 11.1073 9.3924 11.7461 8.9234C11.8928 9.0174 12.0663 9.0724 12.2535 9.0724H12.3006C12.8222 9.0724 13.2441 8.6528 13.2441 8.1338V6.2602C13.2441 5.887 13.0239 5.565 12.7063 5.4154C12.5678 2.4068 10.0616 0 7.00007 0C3.93855 0 1.43189 2.4068 1.29403 5.4154C0.975842 5.5652 0.755868 5.887 0.755868 6.2602V8.1338C0.755868 8.6528 1.17811 9.0724 1.69856 9.0724H1.74625C2.26711 9.0724 2.68955 8.6528 2.68955 8.1338V6.2602C2.68955 5.8924 2.47561 5.5752 2.16467 5.4218C2.29931 2.8868 4.41613 0.8662 7.00007 0.8662C9.583 0.8662 11.7008 2.8868 11.8349 5.4218C11.5243 5.5754 11.3106 5.8924 11.3106 6.2602V8.1338C11.3106 8.2582 11.3349 8.374 11.3778 8.4822C10.828 8.8732 10.0276 9.1518 9.12655 9.2286Z'
                              fill='#E33343'
                            />
                          </svg>
                        </IconButton>
                      )}
                      {props.userData?.userType !== 'user' && (
                        <IconButton
                          className='!text-black !bg-white !rounded-full !text-xs !px-3'
                          onClick={handleHelpClick}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='10'
                            height='14'
                            viewBox='0 0 14 18'
                            fill='none'
                          >
                            <path
                              d='M13.8899 14.7162C13.4846 13.5174 11.8691 12.767 10.7173 12.264C10.2661 12.0676 9.01687 11.7344 8.86673 11.1698C8.81299 10.9664 8.82024 10.7746 8.86431 10.5908C8.79488 10.604 8.72444 10.6118 8.65159 10.6118H7.9011C7.30196 10.6118 6.81511 10.1274 6.81511 9.5324C6.81511 8.9378 7.30216 8.4544 7.9011 8.4544H8.65159C8.89953 8.4544 9.1346 8.5378 9.32419 8.6866C9.60212 8.6498 9.8702 8.5908 10.1202 8.5134C10.4484 7.83 10.7044 7.0128 10.7618 6.3094C11.0067 3.2998 9.1501 1.539 6.48807 1.8434C4.55258 2.0648 3.39636 3.499 3.27138 5.3454C3.14499 7.2274 3.84718 8.6174 4.59304 9.637C4.91968 10.0828 5.26282 10.3694 5.21009 10.9066C5.14891 11.5418 4.46544 11.7188 3.97659 11.914C3.39737 12.1452 2.77347 12.496 2.47883 12.6582C1.46389 13.2152 0.349933 13.886 0.0995689 14.8036C-0.454894 16.8372 1.4176 17.4532 2.96346 17.7376C4.29014 17.9808 5.78609 18 7.01657 18C9.24228 18 13.2445 17.9114 13.8899 16.2488C14.0735 15.777 13.9948 15.0252 13.8899 14.7162Z'
                              fill='#E33343'
                            />
                            <path
                              d='M9.12655 9.2286C9.02472 9.0744 8.85063 8.972 8.65199 8.972H7.9015C7.58834 8.972 7.33577 9.2234 7.33577 9.533C7.33577 9.8438 7.58834 10.0956 7.9015 10.0956H8.65199C8.87116 10.0956 9.05712 9.9714 9.1509 9.7918C10.1972 9.71 11.1073 9.3924 11.7461 8.9234C11.8928 9.0174 12.0663 9.0724 12.2535 9.0724H12.3006C12.8222 9.0724 13.2441 8.6528 13.2441 8.1338V6.2602C13.2441 5.887 13.0239 5.565 12.7063 5.4154C12.5678 2.4068 10.0616 0 7.00007 0C3.93855 0 1.43189 2.4068 1.29403 5.4154C0.975842 5.5652 0.755868 5.887 0.755868 6.2602V8.1338C0.755868 8.6528 1.17811 9.0724 1.69856 9.0724H1.74625C2.26711 9.0724 2.68955 8.6528 2.68955 8.1338V6.2602C2.68955 5.8924 2.47561 5.5752 2.16467 5.4218C2.29931 2.8868 4.41613 0.8662 7.00007 0.8662C9.583 0.8662 11.7008 2.8868 11.8349 5.4218C11.5243 5.5754 11.3106 5.8924 11.3106 6.2602V8.1338C11.3106 8.2582 11.3349 8.374 11.3778 8.4822C10.828 8.8732 10.0276 9.1518 9.12655 9.2286Z'
                              fill='#E33343'
                            />
                          </svg>
                        </IconButton>
                      )}
                      {props.userData?.userType == 'user' && (
                        <div className='!min-w-max'>
                          <div
                            className={` xs:text-[10px] sm:text-[10px] md:text-[10px] font-bold leading-[19px] tracking-normal text-left`}
                          >
                            {_getLocalString('LOC_POINTS')}:{' '}
                            {get2Decimal(props.userData.availablePts)}
                          </div>
                          <div
                            className={` xs:text-[10px] sm:text-[10px] md:text-[10px] font-bold leading-[19px] tracking-normal text-left`}
                          >
                            {_getLocalString('LOC_EXP')}:{' '}
                            {get2Decimal(props.userData.exposure)}
                          </div>
                        </div>
                      )}

                      <div className=''>
                        <NavbarProfileMenus
                          {...props}
                          changePassword={changePassword}
                          logoutUser={props.logoutUser}
                          userType={props.userData?.userType}
                          matchBet={props.matchBet}
                          logoutFunc={props.logoutUser}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {/* ----------------------Mobile non login------------------- */}
                      {/* <div className='relative' style={{ marginLeft: '1rem' }}>
                      <IconButton
                        onClick={(event) => onSearchKeyPress(event)}
                        edge='end'
                        aria-label='search'
                        className='!text-white'
                      >
                        <SearchOutlinedIcon className='' fontSize='large' />
                      </IconButton>
                    </div> */}

                      {enable?.isNightMode && (
                        <LightDarkMode2
                          matchBetPagination={props.matchBetPagination}
                          demoLogin={props.demoLogin}
                          updateOpenLoginScreen={props.updateOpenLoginScreen}
                          openLoginScreen={props.openLoginScreen}
                          key='nightMode2'
                          currentDomain={props.currentDomain}
                          matchBet={props.matchBet}
                          isDarkMode={props.isDarkMode}
                          updateThemeToggle={props.updateThemeToggle}
                          className='!text-red-500'
                        />
                      )}
                    </>
                  )}
                </div>

                {/* -------------------Div 3------------------- */}

                {props.isLogin ? (
                  <></>
                ) : (
                  <>
                    <div className='flex justify-center col-span-2 gap-3'>
                      <div
                        style={{ minWidth: '7rem' }}
                        className={`rounded-md bg-gradient-to-l shadow from-[color:var(--button-first-bg)] !border border-[--theme-color] to-[color:var(--button-second-bg)] ${props.currentDomain.domainName === 'winwave247.com' ? 'bold text-black ' : ' text-white '} text-xs sm:text-sm px-2 sm:px-4 py-1 sm:py-2 cursor-pointer flex gap-1 sm:gap-2 items-center justify-center h-[28px] sm:h-[32px]`}
                        onClick={openLogin}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='12'
                          height='16'
                          viewBox='0 0 12 16'
                          fill='none'
                        >
                          <path
                            d='M12 16H10.6667V12.638C10.6661 12.1153 10.4583 11.6142 10.0887 11.2446C9.71911 10.8751 9.218 10.6672 8.69533 10.6667H3.30467C2.782 10.6672 2.28089 10.8751 1.91131 11.2446C1.54173 11.6142 1.33386 12.1153 1.33333 12.638V16H0V12.638C0.00105855 11.7619 0.349568 10.9219 0.969083 10.3024C1.5886 9.6829 2.42854 9.33439 3.30467 9.33333H8.69533C9.57146 9.33439 10.4114 9.6829 11.0309 10.3024C11.6504 10.9219 11.9989 11.7619 12 12.638V16Z'
                            fill={
                              props.currentDomain.domainName ===
                              'winwave247.com'
                                ? 'black'
                                : 'white'
                            }
                          />
                          <path
                            d='M6 8C5.20887 8 4.43551 7.76541 3.77772 7.32588C3.11992 6.88635 2.60723 6.26164 2.30448 5.53074C2.00173 4.79983 1.92252 3.99556 2.07686 3.21964C2.2312 2.44372 2.61216 1.73098 3.17157 1.17157C3.73098 0.612165 4.44371 0.231202 5.21964 0.0768607C5.99556 -0.0774802 6.79983 0.00173316 7.53073 0.304484C8.26164 0.607234 8.88635 1.11992 9.32588 1.77772C9.7654 2.43552 10 3.20888 10 4C9.99894 5.06054 9.57717 6.07734 8.82725 6.82726C8.07734 7.57718 7.06054 7.99894 6 8ZM6 1.33334C5.47258 1.33334 4.95701 1.48973 4.51848 1.78275C4.07995 2.07577 3.73815 2.49224 3.53632 2.97951C3.33449 3.46678 3.28168 4.00296 3.38457 4.52024C3.48747 5.03753 3.74144 5.51268 4.11438 5.88562C4.48732 6.25856 4.96247 6.51254 5.47976 6.61543C5.99704 6.71832 6.53322 6.66551 7.02049 6.46368C7.50776 6.26185 7.92423 5.92005 8.21725 5.48152C8.51027 5.04299 8.66666 4.52742 8.66666 4C8.66666 3.29276 8.38571 2.61448 7.88562 2.11438C7.38552 1.61429 6.70724 1.33334 6 1.33334Z'
                            fill={
                              props.currentDomain.domainName ===
                              'winwave247.com'
                                ? 'black'
                                : 'white'
                            }
                          />
                        </svg>
                        {_getLocalString('LOC_LOGIN_SMALL_CASE')}
                      </div>
                      {enable?.isRegister && (
                        <div
                          className={`rounded-md bg-gradient-to-l shadow !border border-[--theme-color] from-[color:var(--button-first-bg)] to-[color:var(--button-second-bg)] ${props.currentDomain.domainName === 'winwave247.com' ? 'bold text-black' : 'text-white'} text-xs sm:text-sm px-2 sm:px-4 py-1 sm:py-2 cursor-pointer flex gap-1 sm:gap-2 items-center justify-center h-[28px] sm:h-[32px]`}
                          onClick={openRegister}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='15'
                            height='15'
                            viewBox='0 0 15 15'
                            fill='none'
                          >
                            <path
                              d='M5.00001 13.125H2.13062C1.98958 13.1246 1.87532 13.0104 1.875 12.8694V2.13062C1.87535 1.98958 1.98958 1.87532 2.13062 1.875H4.99998C5.51774 1.875 5.93748 1.45526 5.93748 0.9375C5.93748 0.419736 5.51774 0 4.99998 0H2.13062C0.954492 0.00137695 0.00137695 0.954492 0 2.13062V12.8694C0.00137695 14.0455 0.954492 14.9986 2.13062 15H4.99998C5.51774 15 5.93748 14.5803 5.93748 14.0625C5.93748 13.5447 5.51777 13.125 5.00001 13.125Z'
                              fill={
                                props.currentDomain.domainName ===
                                'winwave247.com'
                                  ? 'black'
                                  : 'white'
                              }
                            />
                            <path
                              d='M14.0625 6.5625L5.53749 6.57938L7.70373 4.41313C8.06997 4.04707 8.07012 3.45343 7.70405 3.08719C7.33799 2.72095 6.74435 2.7208 6.3781 3.08686L3.95373 5.51124C2.85536 6.60958 2.85536 8.39036 3.9537 9.4887L3.95373 9.48873L6.3781 11.9131C6.74435 12.2792 7.33799 12.279 7.70405 11.9128C8.07012 11.5465 8.06997 10.9529 7.70373 10.5868L5.5706 8.45435L14.0625 8.4375C14.5803 8.43647 14.9991 8.01589 14.9981 7.49813C14.9971 6.98036 14.5765 6.56147 14.0587 6.5625H14.0625Z'
                              fill={
                                props.currentDomain.domainName ===
                                'winwave247.com'
                                  ? 'black'
                                  : 'white'
                              }
                            />
                          </svg>
                          {_getLocalString('LOC_REGISTER')}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        open={openRules}
        onClose={() => {
          setOpenRules(false)
        }}
        style={{ zIndex: 999, placeSelf: 'center', width: '90%' }}
        className='overflow-auto scrollbar_hide'
      >
        <Rules
          lang={props.lang}
          languageUpdate={props.languageUpdate}
          languages={languages}
          onCloseModal={setOpenRules}
        />
      </Modal>

      <Modal
        open={isLoginScreen}
        onClose={() => {
          setIsLoginScren(false)
          props.updateOpenLoginScreen(false)
        }}
        style={{ zIndex: 9999 }}
        className='fixed left-0 flex items-center justify-center w-full h-full'
      >
        <Login
          setIsChangePassword={setIsChangePassword}
          setIsRegister={setIsRegister}
          setIsForgetPassword={setIsForgetPassword}
          setIsLoginScren={(isLoginScreen: boolean) => {
            setIsLoginScren(isLoginScreen)
            props.updateOpenLoginScreen(false)
          }}
          currentDomain={props.currentDomain}
          userLogin={props.loginUser}
        />
      </Modal>

      {/* </ReactModal> */}
      <Modal
        className='fixed top-0 left-0 flex items-center justify-center w-full h-full'
        open={isForgetPassword}
        onClose={() => setIsForgetPassword(false)}
      >
        <ForgetPassword
          currentDomain={props.currentDomain}
          setIsChangePassword={setIsChangePassword}
          setIsRegister={setIsRegister}
          setIsForgetPassword={setIsForgetPassword}
          setIsLoginScren={setIsLoginScren}
        />
      </Modal>
      <Modal
        className='fixed top-0 left-0 flex items-center justify-center w-full h-full'
        open={isChangePassword}
        onClose={() => {
          toast.error(
            _getLocalString('LOC_CHNAGE_PASSWORD_AND_COPY_TRANSACTION_CODE'),
          )
        }}
      >
        <ChangePassword
          changePasswordFuncData={emptyFunction}
          userType={props.userData?.userType}
          logoutUser={props.logoutUser}
          currentDomain={props.currentDomain}
          setIsChangePassword={setIsChangePassword}
          changePassword={props.changePassword}
        />
      </Modal>
      <Modal
        className='fixed left-0 flex items-center justify-center w-full h-full'
        open={isRegister}
        onClose={() => setIsRegister(false)}
      >
        <Register
          appThemeConfiguration={props.appThemeConfiguration}
          setIsLoginScren={setIsLoginScren}
          setIsRegister={setIsRegister}
          setIsTermsAndConditions={setIsTermsAndConditions}
          userSignUp={props.signUpUser}
        />
      </Modal>
      <Modal
        className='overflow-auto termsModal scrollbar_hide'
        open={isTermsAndConditions}
        onClose={() => setIsTermsAndConditions(false)}
      >
        <TermsAndConditions setIsTermsAndConditions={setIsTermsAndConditions} />
      </Modal>
    </div>
  )
}

export const LightDarkMode = (props: IHeader) => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('APP_CONFIGURATION_DARK_MODE') === 'light'
      ? true
      : false,
  )

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode)
    props.updateThemeToggle && props.updateThemeToggle(!isDarkMode)
  }

  return (
    <div className='cursor-pointer'>
      <div onClick={toggleDarkMode}>
        {isDarkMode ? (
          <IoMdMoon style={{ color: 'white', fontSize: '20px' }} />
        ) : (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M10.5859 4.37546C10.3934 4.35562 10.1981 4.3454 10 4.3454C6.88187 4.3454 4.3454 6.88187 4.3454 10C4.3454 13.118 6.88187 15.6544 10 15.6544C10.1981 15.6544 10.3934 15.6444 10.5859 15.6244C13.4297 15.3304 15.6549 12.9204 15.6549 10C15.6549 7.07962 13.4297 4.66965 10.5859 4.37546ZM10.5859 14.4444V5.55542C12.7821 5.84335 14.4832 7.72644 14.4832 10C14.4832 12.2736 12.7821 14.1566 10.5859 14.4444Z'
              fill='url(#paint0_linear_202_11346)'
            />
            <path
              d='M10 3.49777C10.3235 3.49777 10.5859 3.23532 10.5859 2.91183V0.585938C10.5859 0.262299 10.3235 0 10 0C9.67636 0 9.41406 0.262299 9.41406 0.585938V2.91183C9.41406 3.23532 9.67636 3.49777 10 3.49777Z'
              fill='url(#paint1_linear_202_11346)'
            />
            <path
              d='M10 16.5022C9.67636 16.5022 9.41406 16.7647 9.41406 17.0882V19.4141C9.41406 19.7377 9.67636 20 10 20C10.3235 20 10.5859 19.7377 10.5859 19.4141V17.0882C10.5859 16.7645 10.3236 16.5022 10 16.5022Z'
              fill='url(#paint2_linear_202_11346)'
            />
            <path
              d='M3.49777 10C3.49777 9.67636 3.23532 9.41406 2.91183 9.41406H0.585938C0.262299 9.41406 0 9.67636 0 10C0 10.3235 0.262299 10.5859 0.585938 10.5859H2.91183C3.23532 10.5859 3.49777 10.3236 3.49777 10Z'
              fill='url(#paint3_linear_202_11346)'
            />
            <path
              d='M19.4141 9.41406H17.0882C16.7645 9.41406 16.5022 9.67636 16.5022 10C16.5022 10.3235 16.7645 10.5859 17.0882 10.5859H19.4141C19.7377 10.5859 20 10.3235 20 10C20 9.67636 19.7377 9.41406 19.4141 9.41406Z'
              fill='url(#paint4_linear_202_11346)'
            />
            <path
              d='M4.43298 5.26154C4.54742 5.37598 4.69742 5.4332 4.84726 5.4332C4.99725 5.4332 5.14709 5.37598 5.26154 5.26154C5.49026 5.03265 5.49026 4.66171 5.26154 4.43298L3.57071 2.74216C3.34198 2.51328 2.97089 2.51328 2.74216 2.74216C2.51328 2.97089 2.51328 3.34183 2.74216 3.57071L4.43298 5.26154Z'
              fill='url(#paint5_linear_202_11346)'
            />
            <path
              d='M15.567 14.7385C15.3383 14.5096 14.9673 14.5096 14.7385 14.7385C14.5097 14.9672 14.5097 15.3381 14.7385 15.567L16.4293 17.2578C16.5437 17.3723 16.6937 17.4295 16.8436 17.4295C16.9936 17.4295 17.1434 17.3723 17.2578 17.2578C17.4866 17.029 17.4866 16.658 17.2578 16.4293L15.567 14.7385Z'
              fill='url(#paint6_linear_202_11346)'
            />
            <path
              d='M4.43298 14.7385L2.74216 16.4293C2.51343 16.658 2.51343 17.029 2.74216 17.2578C2.8566 17.3723 3.00644 17.4295 3.15643 17.4295C3.30627 17.4295 3.45627 17.3723 3.57071 17.2578L5.26154 15.567C5.49026 15.3381 5.49026 14.9672 5.26154 14.7385C5.03281 14.5096 4.66171 14.5096 4.43298 14.7385Z'
              fill='url(#paint7_linear_202_11346)'
            />
            <path
              d='M15.1527 5.4332C15.3027 5.4332 15.4526 5.37598 15.567 5.26154L17.2578 3.57071C17.4866 3.34183 17.4866 2.97089 17.2578 2.74216C17.0291 2.51328 16.658 2.51328 16.4293 2.74216L14.7385 4.43298C14.5096 4.66171 14.5096 5.03265 14.7385 5.26154C14.8529 5.37598 15.0027 5.4332 15.1527 5.4332Z'
              fill='url(#paint8_linear_202_11346)'
            />
            <defs>
              <linearGradient
                id='paint0_linear_202_11346'
                x1='0.6'
                y1='4.61539'
                x2='22.2972'
                y2='5.02865'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#ECF036' />
                <stop offset='1' stopColor='var(--inPlay-match-gradientTo)' />
              </linearGradient>
              <linearGradient
                id='paint1_linear_202_11346'
                x1='0.6'
                y1='4.61539'
                x2='22.2972'
                y2='5.02865'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#ECF036' />
                <stop offset='1' stopColor='var(--inPlay-match-gradientTo)' />
              </linearGradient>
              <linearGradient
                id='paint2_linear_202_11346'
                x1='0.6'
                y1='4.61539'
                x2='22.2972'
                y2='5.02865'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#ECF036' />
                <stop offset='1' stopColor='var(--inPlay-match-gradientTo)' />
              </linearGradient>
              <linearGradient
                id='paint3_linear_202_11346'
                x1='0.6'
                y1='4.61539'
                x2='22.2972'
                y2='5.02865'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#ECF036' />
                <stop offset='1' stopColor='var(--inPlay-match-gradientTo)' />
              </linearGradient>
              <linearGradient
                id='paint4_linear_202_11346'
                x1='0.6'
                y1='4.61539'
                x2='22.2972'
                y2='5.02865'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#ECF036' />
                <stop offset='1' stopColor='var(--inPlay-match-gradientTo)' />
              </linearGradient>
              <linearGradient
                id='paint5_linear_202_11346'
                x1='0.6'
                y1='4.61539'
                x2='22.2972'
                y2='5.02865'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#ECF036' />
                <stop offset='1' stopColor='var(--inPlay-match-gradientTo)' />
              </linearGradient>
              <linearGradient
                id='paint6_linear_202_11346'
                x1='0.6'
                y1='4.61539'
                x2='22.2972'
                y2='5.02865'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#ECF036' />
                <stop offset='1' stopColor='var(--inPlay-match-gradientTo)' />
              </linearGradient>
              <linearGradient
                id='paint7_linear_202_11346'
                x1='0.6'
                y1='4.61539'
                x2='22.2972'
                y2='5.02865'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#ECF036' />
                <stop offset='1' stopColor='var(--inPlay-match-gradientTo)' />
              </linearGradient>
              <linearGradient
                id='paint8_linear_202_11346'
                x1='0.6'
                y1='4.61539'
                x2='22.2972'
                y2='5.02865'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#ECF036' />
                <stop offset='1' stopColor='var(--inPlay-match-gradientTo)' />
              </linearGradient>
            </defs>
          </svg>
        )}
      </div>
    </div>
  )
}

export const LightDarkMode2 = (props: IHeader) => {
  const [isDarkMode, setIsDarkMode] = useState(false)

  const toggleDarkMode2 = () => {
    setIsDarkMode((prevMode) => !prevMode)
    props.updateThemeToggle && props.updateThemeToggle(!isDarkMode)
  }

  return (
    <div className='cursor-pointer'>
      <div onClick={toggleDarkMode2}>
        {isDarkMode ? (
          <IoMdMoon style={{ color: 'white', fontSize: '20px' }} />
        ) : (
          <IoMdSunny style={{ color: 'yellow', fontSize: '20px' }} />
        )}
      </div>
    </div>
  )
}
export interface IDropDown {
  update?: (lang: string) => void
  selectedValue?: string
  item: IDropDownItem[]
  className?: string
  classes?: any
  containerClass?: string
}

export interface IDropDownItem {
  key: string
  value: string
}

export const DropDown = (props: IDropDown) => {
  const handleChange = (event: SelectChangeEvent) => {
    props.update && props.update(event.target.value)
  }

  return (
    <>
      <Select
        value={props.selectedValue}
        size='small'
        onChange={handleChange}
        className='!rounded-md !text-white !border-solid !border-[#ffffff21] border-[1px] searchBox_input !text-base !w-[120px] h-[38px] !py-0'
        classes={{
          icon: '!text-white',
        }}
        MenuProps={{
          MenuListProps: {
            classes: {
              root: '!bg-[color:var(--header-top-forth-bg)] !text-white',
            },
          },
        }}
      >
        {props.item &&
          props.item.map((item, index) => (
            <MenuItem
              key={index}
              value={item.key}
              className='font-primary !flex !text-sm '
            >
              <div className='flex items-center gap-2'>
                <span className=''>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                  >
                    <g clipPath='url(#clip0_490_13505)'>
                      <path
                        d='M6.11595 8.37684C6.09029 8.24841 5.20648 3.82934 5.17845 3.68931C5.13466 3.47022 4.94226 3.3125 4.71882 3.3125H3.78132C3.55788 3.3125 3.36548 3.47022 3.3217 3.68931C3.29323 3.83172 2.40851 8.25528 2.3842 8.37681C2.33341 8.63069 2.49807 8.87763 2.75191 8.92838C3.00576 8.97916 3.25273 8.8145 3.30348 8.56066L3.6031 7.0625H4.89704L5.19666 8.56069C5.24745 8.81466 5.49451 8.97919 5.74823 8.92841C6.00207 8.87763 6.16673 8.63069 6.11595 8.37684ZM3.7906 6.125L4.1656 4.25H4.33454L4.70954 6.125H3.7906Z'
                        fill='#EDE737'
                      />
                      <path
                        d='M13.625 7.0625H12.2188V6.59375C12.2188 6.33488 12.0089 6.125 11.75 6.125C11.4911 6.125 11.2812 6.33488 11.2812 6.59375V7.0625H9.875C9.61612 7.0625 9.40625 7.27238 9.40625 7.53125C9.40625 7.79013 9.61612 8 9.875 8H9.98844C10.2555 8.86288 10.6577 9.52519 11.1002 10.0559C10.74 10.3854 10.3756 10.6555 10.0509 10.9152C9.84878 11.0769 9.816 11.3719 9.97775 11.5741C10.1396 11.7763 10.4346 11.8089 10.6366 11.6473C10.963 11.3861 11.3543 11.0959 11.75 10.7328C12.146 11.0961 12.538 11.3869 12.8634 11.6473C13.0656 11.809 13.3606 11.7762 13.5223 11.5741C13.684 11.3719 13.6512 11.0769 13.4491 10.9152C13.1253 10.6561 12.7604 10.3857 12.3998 10.0559C12.8423 9.52519 13.2445 8.86288 13.5116 8H13.625C13.8839 8 14.0938 7.79013 14.0938 7.53125C14.0938 7.27238 13.8839 7.0625 13.625 7.0625ZM11.75 9.36703C11.4508 8.99141 11.1816 8.54306 10.9787 7.99688H12.5213C12.3184 8.54306 12.0492 8.99141 11.75 9.36703Z'
                        fill='#F7B33D'
                      />
                      <path
                        d='M14.5938 2.84375H7.83122L7.63028 1.23181C7.5425 0.529562 6.94263 0 6.23491 0H1.40625C0.630844 0 0 0.630844 0 1.40625V11.75C0 12.5254 0.630844 13.1562 1.40625 13.1562H5.35884L5.55722 14.7682C5.64484 15.469 6.24472 16 6.95262 16H14.5938C15.3692 16 16 15.3692 16 14.5938V4.25C16 3.47459 15.3692 2.84375 14.5938 2.84375ZM1.40625 12.2188C1.14778 12.2188 0.9375 12.0085 0.9375 11.75V1.40625C0.9375 1.14778 1.14778 0.9375 1.40625 0.9375H6.23491C6.47081 0.9375 6.67078 1.114 6.7 1.34794C6.76794 1.89281 7.98775 11.6783 8.05513 12.2188H1.40625ZM6.46012 14.4297L6.30341 13.1562H7.56103L6.46012 14.4297ZM15.0625 14.5938C15.0625 14.8522 14.8522 15.0625 14.5938 15.0625H7.15231L8.93681 12.9983C9.02816 12.8953 9.06909 12.7585 9.05022 12.6226L7.94809 3.78125H14.5938C14.8522 3.78125 15.0625 3.99153 15.0625 4.25V14.5938Z'
                        fill='url(#paint0_linear_490_13505)'
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id='paint0_linear_490_13505'
                        x1='-0.4'
                        y1='2'
                        x2='16.8'
                        y2='8'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#EDEA37' />
                        <stop offset='1' stopColor='#F8AC3E' />
                      </linearGradient>
                      <clipPath id='clip0_490_13505'>
                        <rect width='16' height='16' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span className='!text-white capitalize'>{item.value}</span>
              </div>
            </MenuItem>
          ))}
      </Select>
    </>
  )
}
