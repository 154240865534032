import { FC, useEffect, useRef, useState } from 'react'
import { SportsBook } from '../../landing/component/sportsBook'
import { GameHeader, HighLightContent } from './GameHeader'
import { GameContent } from './GameContent'
import { ScheduleContent } from './ScheduleContent'
import { emptyFunction, getAssetUrl } from '../../../utils'
import { APP_SELECTED_EVENT_ID, SET_USER_QUICK_BET } from '../../../redux'
import { useDispatch, useSelector } from 'react-redux'
import { HeaderBottom } from '../../landing/component/HeaderBottom'
import ConnectedBetSlipNew from '../betslip/ConnectedBetSlipNew'
import Marquee from 'react-fast-marquee'
import { UpCommingMatches } from '../../Sports/upcommingMatches/UpCommingMatches'
import { ICurrentBet } from '../betslip/NewBetSlip'
import { FastestOdd } from '../../landing/component/fastestOdd/FastestOdd'
import { URLType, makeGetHttpCallAsync } from '../../../api'
import { useNavigate, useParams } from 'react-router-dom'
import { CircularProgress, Drawer, useMediaQuery } from '@mui/material'
import {
  GET_ONGOING_MATCHES,
  getEventTypeFilteredEvents,
} from '../../../redux/actions/matchesAction/SchedulesMatchesAction'
import { ICurrentDomain } from '../../../types/interfaces/currentDomain'
import {
  ISlideShow,
  IThemeConfiguration,
} from '../../../types/interfaces/themeConfiguration'
import {
  getLiveCasinoData,
  getVirtualCasinoData,
} from '../../../utils/gameUtils/casinoHelper'
import { sortEventsByOpenDate } from '../../../app-util/commonUtil'
import 'swiper/css'
import 'swiper/css/free-mode'
import {
  Navigation,
  Pagination as SwipperPagination,
  FreeMode,
  Autoplay,
} from 'swiper/modules'
import { SwiperOptions } from 'swiper/types/swiper-options'
import { getAllEventsLength } from '../../../utils/gameUtils/gameUtil'
import MainBanner from '../../../component/banner/MainBanner'
import GameBannerSlider from '../../../component/banner/GameBannerSlider'
import ConnectedBetSlipOn from '../betslip/ConnectedBetSlipOn'
import { MdOutlineExpandMore } from 'react-icons/md'
import { IoClose, IoDocumentTextOutline } from 'react-icons/io5'
import { BsLightningFill } from 'react-icons/bs'
import { SAVE_STATUS_MARKETIDS } from '../../../redux/actions/marketStatusAction'
import { InPlaySportsContent } from './InPlaySportsContent'
import { TopWinners } from '../../../component/TopWinners'
import { CasinoAndPartnerContent } from '../../../component/CasinoAndPartnerContent'
import { decrypt } from '../../../api/authHelper'
import { isActualFuture9 } from '../../../App'
import { Helmet } from 'react-helmet'

export interface IContent {
  isDarkMode?: boolean
  getFavoriteEvent: any
  liveCasinoData: any
  isHighLight: boolean
  virtualCasinoData: any
  deletedEventId: string
  appThemeConfiguration: IThemeConfiguration
  favoriteListEventNames: any
  currentBet: ICurrentBet
  onMarketClick: (market: ICurrentBet) => void
  updateOpenLoginScreen: (openLoginScreen: boolean) => void
  upComingMatches: any[]
  fastestOdds: any[]
  getFastestOdds: any
  commonGame: any
  ongoingMatches: any[]
  currentDomain: ICurrentDomain
  eventFavoriteList: any
  marketLoader: boolean
  addFavoriteEventList: (
    currentDomain: ICurrentDomain,
    payload: any,
    cb: any,
  ) => void
  deleteFavoriteEvent: (
    currentDomain: ICurrentDomain,
    payload: any,
    cb: any,
  ) => void
  themeConfiguration?: IThemeConfiguration
  isLogin?: boolean
}

export const Content: FC<IContent> = (props: IContent) => {
  useEffect(() => {
    if (isActualFuture9() && props.currentDomain.type === 'b2c')
      // document.title = 'Sports Exchange | Everything you need to Win at Betting'
      // TODO: TITLE COMMENT

      return () => {
        // TODO: TITLE COMMENT
        // document.title =
        //   props?.appThemeConfiguration?.logoText +
        //   ' | Most Trusted Sports & Casino Platform'
      }
  }, [])

  const dispatch = useDispatch()
  const [isHighLight, setIsHighLight] = useState(true)
  const [isMaketShown, setIsMarketShown] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const [marketStatus, setMarketStatus] = useState([])
  const { gameType, id } = useParams()
  const navigate = useNavigate()

  const isLargeScreen = useMediaQuery('(min-width:1200px)')

  const { slideShow } = useSelector((state: any) => state.appThemeConfiguration)
  const { globaSearchContent } = useSelector(
    (state: any) => state.appConfiguration,
  )
  const { quickBetValue, quickBet } = useSelector((state: any) => state.login)

  const isSmallScreen = useMediaQuery('(max-width:900px)')

  const swiperModules = [FreeMode, Navigation]
  const swiperOptions: SwiperOptions = {
    freeMode: true,
    wrapperClass: 'items-center',
    breakpoints: {
      0: {
        slidesPerView: 'auto',
        direction: 'horizontal',
        spaceBetween: 20,
      },
    },
  }

  useEffect(() => {
    let decryptId = decrypt(id)
    if (!decryptId) {
      dispatch({
        type: APP_SELECTED_EVENT_ID,
        payload: '',
      })
      dispatch({
        type: SET_USER_QUICK_BET,
        payload: false,
      })
      return setIsMarketShown(false)
    }
    if (decryptId) {
      dispatch({
        type: APP_SELECTED_EVENT_ID,
        payload: decryptId,
      })
    }
  }, [id])

  useEffect(() => {
    let decryptId = decrypt(id)
    if (!gameType) {
      return setIsMarketShown(false)
    }

    if (gameType === 'favorite') {
      props.getFavoriteEvent(props.currentDomain)
      return
    }

    if (decryptId === 'politics') {
      return
    }

    if (gameType === 'inPlay') {
      setIsLoader(true)
      makeGetHttpCallAsync(
        dispatch,
        'api/common/game/ongoing',
        'active=true',
        URLType.master,
        false,
        true,
      )
        .then((response: any) => {
          setIsLoader(false)
          let marketIds = ''
          response.docs.forEach((doc: any) => {
            doc.events.forEach((event: any) => {
              if (typeof event.marketId !== 'string') {
                marketIds += `${event.marketId._id};`
              }
            })
          })

          dispatch({
            type: SAVE_STATUS_MARKETIDS,
            data: marketIds,
          })

          dispatch({
            type: GET_ONGOING_MATCHES,
            payload: sortEventsByOpenDate(
              response.docs.filter(
                (item: any) => typeof item.events[0].marketId !== 'string',
              ),
            ),
          })
          return setIsMarketShown(false)
        })
        .catch(() => {
          setIsLoader(false)
        })
      return
    }

    if (isNaN(parseInt(gameType))) {
      navigate('/')
      return
    }

    setIsLoader(true)

    props.commonGame &&
      props.commonGame.length &&
      makeGetHttpCallAsync(
        dispatch,
        'api/common/game/market/status/eventType',
        `eventTypeId=${gameType}&active=true
      `,
        URLType.master,
        false,
        false,
      )
        .then((response: any) => {
          setIsLoader(false)
          setIsMarketShown(true)
          let filteredEvents: any = getEventTypeFilteredEvents(
            gameType!,
            response.docs,
            props.commonGame,
          )

          dispatch({
            type: SAVE_STATUS_MARKETIDS,
            data: filteredEvents.marketIds,
          })

          setMarketStatus(filteredEvents.res[0].events)
        })
        .catch(() => {
          dispatch({
            type: SAVE_STATUS_MARKETIDS,
            data: [],
          })

          setMarketStatus([])
          setIsLoader(false)
          setIsMarketShown(true)
        })
  }, [gameType, props.commonGame])

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleHighLight = () => {
    setIsHighLight(!isHighLight)
  }

  const headerBottom = useSelector(
    (state: any) => state.appThemeConfiguration.headerBottom,
  )

  const divRef = useRef<HTMLDivElement>(null)
  const [divHeight, setDivHeight] = useState<number>(0)

  useEffect(() => {
    if (divRef.current) {
      setDivHeight(divRef.current!.offsetHeight)
    }
  }, [props.ongoingMatches, props.upComingMatches])

  const onMarketClick = (payload: any) => {
    if (isLargeScreen) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    props.onMarketClick(payload)
  }

  let bannerDetailsLocal: ISlideShow[] = useSelector(
    (state: any) => state.appCommonTheme.slideShow,
  )

  const [bannerDetails, setBannerDetails] = useState<any[]>(
    !!slideShow && !!slideShow.length && !!slideShow[0].image
      ? slideShow
      : bannerDetailsLocal,
  )

  useEffect(() => {
    setBannerDetails(
      !!slideShow && !!slideShow.length && !!slideShow[0].image
        ? slideShow
        : bannerDetailsLocal,
    )
  }, [bannerDetailsLocal, slideShow])

  const showNavBar = useMediaQuery('(max-width:500px)')

  return (
    <>
      {isActualFuture9() && props.currentDomain.type === 'b2c' && (
        <Helmet>
          <meta
            name='description'
            content={
              'Check out the best betting exchange sites in India. Understand what a betting exchange is, and how a sports betting exchange works.'
            }
          />
        </Helmet>
      )}
      <div className='grid grid-cols-9 col-span-12  md:px-0 lg:col-span-10'>
        <div ref={divRef} className='col-span-12 lg:col-span-6'>
          {!props.marketLoader && (
            <>
              <HighLightContent
                setIsHighLight={toggleHighLight}
                isHighLight={isHighLight}
                isDarkMode={props.isDarkMode || true}
              />
              {isHighLight ? (
                <>
                  <GameContent />
                </>
              ) : (
                <>
                  <ScheduleContent upComingMatches={props.upComingMatches} />
                </>
              )}

              <GameBannerSlider>
                {bannerDetails &&
                  bannerDetails.map((item: any, index: number) => (
                    <MainBanner
                      key={index}
                      isCommon={!(!!slideShow && !!slideShow.length)}
                      banner={item}
                    />
                  ))}
              </GameBannerSlider>
            </>
          )}

          <div className='dark:bg-black bg-white rounded-md mb-1 xs:ml-[-2vh] xs:mr-[-2vh] md:ml-0 md:mr-0'>
            {true && (
              <div className='bg-[var(--header-bottom-marquee-bg)]  text-center h-[20px] flex md:hidden sm:hidden xs:hidden'>
                <div className='text-[color:--header-bottom-marquee-text] '>
                  <Marquee className='!text-[13px] font-medium leading-[87px] tracking-[0.03em] text-left text-[var(--header-bottom-marquee-text)]'>
                    {headerBottom?.marqueeText}
                  </Marquee>
                </div>
              </div>
            )}

            <div className='block lg:hidden'>
              <HeaderBottom />
            </div>
            <div className='mt-2'>
              <GameHeader />
            </div>
            <div className='py-1 pl-8 md:py-1 md:pl-0'></div>
          </div>

          <>
            {gameType === 'politics' ? (
              <>
                <div className=''>
                  <div
                    className={`md:grid hidden  grid-cols-12 xl:pr-4 items-center text-white  dark:!bg-[var(--inPlay-match-background)] !bg-white shadow-[0px_4px_44px_0px_#0000001a]`}
                  >
                    <div className='min-h-[50px]  md:w-auto w-[150px] text-white bg-gradient-to-r col-span-3 from-[var(--inPlay-match-gradientFrom)] to-[var(--inPlay-match-gradientTo)] py-4 px-5 flex items-center font-bold text-lg capitalize'>
                      <img
                        src={getAssetUrl(
                          `sports/png/${'politics'
                            .replace(/\s/g, '')
                            .toLowerCase()}.webp`,
                        )}
                        alt='Sports Image'
                        style={{ height: '1rem', marginRight: '1rem' }}
                      />
                      {_getLocalString('LOC_POLITICS')}
                    </div>

                    <div className='col-span-5 '></div>

                    <div className='flex justify-around col-span-4 '>
                      <div className='pr-3 font-normal leading-5 tracking-normal text-center text-black text-2l dark:text-white font-primary'>
                        1
                      </div>
                      <div className='pr-3 font-normal leading-5 tracking-normal text-center text-black text-2l dark:text-white font-primary'>
                        X
                      </div>
                      <div className='pr-3 font-normal leading-5 tracking-normal text-center text-black text-2l dark:text-white font-primary'>
                        2
                      </div>
                    </div>
                  </div>

                  <div
                    className={` grid md:hidden  xs:grid-cols-3  items-center text-white bg-transparent  dark:bg-[var(--inPlay-match-background)]   `}
                  >
                    <div className='gap-4 text-white bg-gradient-to-r md:col-span-2 from-[var(--inPlay-match-gradientFrom)] to-[var(--inPlay-match-gradientTo)] lg:py-4 sm:py-3 py-2 px-3 text-[15px] flex capitalize  items-center font-bold'>
                      {'Politics'}
                    </div>
                  </div>
                  <div className='grid grid-cols-3 col-span-3  bg-white dark:bg-[#FFEFF2] text-black border-t md:hidden'>
                    <div className='bold text-center '> 1 </div>
                    <div className='bold text-center'> X </div>
                    <div className='bold text-center '> 2 </div>
                  </div>
                  <div> No Market Found </div>
                </div>
              </>
            ) : gameType === 'favorite' ? (
              <div className='px-1 lg:px-0'>
                <div>
                  {!!props.favoriteListEventNames &&
                  !!props.favoriteListEventNames.length ? (
                    <UpCommingMatches
                      isDrawerOpen={isDrawerOpen}
                      setIsDrawerOpen={setIsDrawerOpen}
                      addFavoriteEventList={props.addFavoriteEventList}
                      currentDomain={props.currentDomain}
                      deleteFavoriteEvent={props.deleteFavoriteEvent}
                      eventFavoriteList={props.eventFavoriteList}
                      updateOpenLoginScreen={emptyFunction}
                      isPopUpOpen={false}
                      currentBet={props.currentBet}
                      markets={props.favoriteListEventNames}
                      isDarkMode={props.isDarkMode || true}
                      isUpComing={false}
                      isFavorites={true}
                      isLogin={props.isLogin || false}
                      onMarketClick={props.onMarketClick}
                    />
                  ) : (
                    <div className='text-center text-black dark:text-white'>
                      {' '}
                      No Favorites Found{' '}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                {isLoader ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100px',
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : globaSearchContent && globaSearchContent.length ? (
                  <UpCommingMatches
                    isDrawerOpen={isDrawerOpen}
                    setIsDrawerOpen={setIsDrawerOpen}
                    addFavoriteEventList={props.addFavoriteEventList}
                    currentDomain={props.currentDomain}
                    deleteFavoriteEvent={props.deleteFavoriteEvent}
                    eventFavoriteList={props.eventFavoriteList}
                    updateOpenLoginScreen={emptyFunction}
                    isPopUpOpen={false}
                    currentBet={props.currentBet}
                    markets={globaSearchContent}
                    isDarkMode={props.isDarkMode || false}
                    isUpComing={false}
                    isLogin={props.isLogin || false}
                    onMarketClick={props.onMarketClick}
                  />
                ) : !isMaketShown ? (
                  <div className='px-1 lg:px-0'>
                    <div>
                      <UpCommingMatches
                        isDrawerOpen={isDrawerOpen}
                        setIsDrawerOpen={setIsDrawerOpen}
                        addFavoriteEventList={props.addFavoriteEventList}
                        currentDomain={props.currentDomain}
                        deleteFavoriteEvent={props.deleteFavoriteEvent}
                        eventFavoriteList={props.eventFavoriteList}
                        updateOpenLoginScreen={emptyFunction}
                        isPopUpOpen={false}
                        currentBet={props.currentBet}
                        markets={props.ongoingMatches}
                        isDarkMode={props.isDarkMode || true}
                        isUpComing={false}
                        isLogin={props.isLogin || false}
                        onMarketClick={props.onMarketClick}
                      />
                    </div>

                    {gameType !== 'inPlay' &&
                      ((!!props.upComingMatches.length &&
                        !props.ongoingMatches.length) ||
                        (!!props.ongoingMatches.length &&
                          getAllEventsLength(props.ongoingMatches) < 10 &&
                          !!props.upComingMatches.length &&
                          getAllEventsLength(props.upComingMatches) < 10)) && (
                        <div>
                          <UpCommingMatches
                            isDrawerOpen={isDrawerOpen}
                            setIsDrawerOpen={setIsDrawerOpen}
                            addFavoriteEventList={props.addFavoriteEventList}
                            currentDomain={props.currentDomain}
                            deleteFavoriteEvent={props.deleteFavoriteEvent}
                            eventFavoriteList={props.eventFavoriteList}
                            updateOpenLoginScreen={emptyFunction}
                            isPopUpOpen={false}
                            currentBet={props.currentBet}
                            markets={props.upComingMatches}
                            isDarkMode={props.isDarkMode || false}
                            isUpComing={true}
                            isLogin={props.isLogin || false}
                            onMarketClick={props.onMarketClick}
                          />
                        </div>
                      )}
                  </div>
                ) : (
                  <div>
                    <InPlaySportsContent
                      isUpComing={false}
                      isPopUpOpen={false}
                      onGoing={false}
                      addFavoriteEventList={props.addFavoriteEventList}
                      currentBet={props.currentBet}
                      currentDomain={props.currentDomain}
                      deleteFavoriteEvent={props.deleteFavoriteEvent}
                      eventFavoriteList={props.eventFavoriteList}
                      isDrawerOpen={isDrawerOpen}
                      isLogin={props.isLogin || false}
                      isMaketShown={isMaketShown}
                      marketStatus={marketStatus}
                      onMarketClick={props.onMarketClick}
                      setIsDrawerOpen={setIsDrawerOpen}
                      updateOpenLoginScreen={props.updateOpenLoginScreen}
                    />
                  </div>
                )}
              </>
            )}
          </>
        </div>

        <div className='hidden col-span-3 lg:block'>
          {!isSmallScreen && <ConnectedBetSlipNew />}
          <SportsBook
            divHeight={divHeight}
            updateOpenLoginScreen={props.updateOpenLoginScreen}
            isLogin={props.isLogin || false}
          />
        </div>
        <div className='flex col-span-12 lg:hidden  !w-full'>
          <SportsBook
            divHeight={divHeight}
            updateOpenLoginScreen={props.updateOpenLoginScreen}
            isLogin={props.isLogin || false}
          />
        </div>
      </div>

      <div className=' md:col-span-12'>
        <div
          className={`pb-14  dark:!bg-none !bg-no-repeat  !bg-[length:100%_100%] grid grid-cols-9 bg-center bg-blend-multiply`}
        >
          <div className='hidden col-span-1 md:block'></div>
          <div className='col-span-9 px-1 md:px-0 md:col-span-7'>
            {!!Object.keys(props.fastestOdds).length && (
              <div
                className={`xs:pl-0 xs:pr-0 xs:mt-8 sm:mt-8 md:mt-10  md:py-10 dark:!bg-none  bg-cover bg-center bg-blend-multiply md:px-0 px-4`}
              >
                <FastestOdd
                  isLogin={props.isLogin || false}
                  setIsDrawerOpen={setIsDrawerOpen}
                  PlaceOrder={onMarketClick}
                  updateOpenLoginScreen={props.updateOpenLoginScreen}
                  fastestOdds={props.fastestOdds}
                  isDarkMode={props.isDarkMode}
                />
              </div>
            )}

            <>
              {gameType !== 'inPlay' &&
                !(globaSearchContent && globaSearchContent.length) &&
                !isMaketShown &&
                !(
                  (!!props.upComingMatches.length &&
                    !props.ongoingMatches.length) ||
                  (!!props.ongoingMatches.length &&
                    getAllEventsLength(props.ongoingMatches) < 10 &&
                    !!props.upComingMatches.length &&
                    getAllEventsLength(props.upComingMatches) < 10)
                ) && (
                  <div className='col-span-9 px-0 md:px-0 lg:col-span-5 md:col-span-7'>
                    <UpCommingMatches
                      isDrawerOpen={isDrawerOpen}
                      setIsDrawerOpen={setIsDrawerOpen}
                      addFavoriteEventList={props.addFavoriteEventList}
                      currentDomain={props.currentDomain}
                      deleteFavoriteEvent={props.deleteFavoriteEvent}
                      eventFavoriteList={props.eventFavoriteList}
                      updateOpenLoginScreen={emptyFunction}
                      isPopUpOpen={false}
                      currentBet={props.currentBet}
                      markets={props.upComingMatches}
                      isDarkMode={props.isDarkMode || false}
                      isUpComing={true}
                      isLogin={props.isLogin || false}
                      onMarketClick={props.onMarketClick}
                    />
                    <div></div>
                  </div>
                )}
            </>

            <CasinoAndPartnerContent
              getVirtualCasinoData={() => getVirtualCasinoData(_getLocalString)}
              Autoplay={Autoplay}
              SwipperPagination={SwipperPagination}
              appThemeConfiguration={props.appThemeConfiguration}
              getLiveCasinoData={() => getLiveCasinoData(_getLocalString)}
              isDarkMode={props.isDarkMode || false}
              swiperModules={swiperModules}
              swiperOptions={swiperOptions}
              themeConfiguration={props.themeConfiguration}
            />
          </div>
        </div>
        {props.currentDomain.type !== 'b2b' && <TopWinners />}
      </div>

      {!isLargeScreen && (
        <div
          style={{
            position: 'fixed',
            bottom: '75px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '998',
            display: showNavBar ? 'none' : '',
          }}
        >
          <button
            style={{
              position: 'relative',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              backgroundColor: !quickBet ? '#f57c3b' : 'purple',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={toggleDrawer}
          >
            {isDrawerOpen ? (
              <IoClose style={{ fontSize: '30px', color: '#ffffff' }} />
            ) : !quickBet ? (
              <IoDocumentTextOutline
                style={{ fontSize: '30px', color: '#ffffff' }}
              />
            ) : (
              <div style={{ position: 'relative' }}>
                <BsLightningFill
                  style={{ fontSize: '30px', color: '#ffffff' }}
                />
                <div
                  className='px-2 font-bold'
                  style={{
                    position: 'absolute',
                    top: '-18px',
                    right: '-35px',
                    backgroundColor: 'white',
                    borderRadius: '30%',
                    minWidth: '15px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    color: 'black',
                  }}
                >
                  {quickBetValue}
                </div>
              </div>
            )}
          </button>
        </div>
      )}

      <Drawer anchor='bottom' open={isDrawerOpen} onClose={toggleDrawer}>
        <div
          role='presentation'
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          style={{ zIndex: '999' }}
        >
          <ConnectedBetSlipOn />
          <button
            style={{
              borderRadius: '50%',
              width: '25px',
              height: '25px',
              backgroundColor: '#f57c3b',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              cursor: 'pointer',
              position: 'absolute',
              top: '6px',
              right: '6px',
            }}
            onClick={toggleDrawer}
          >
            <MdOutlineExpandMore
              style={{ fontSize: '30px', color: '#ffffff' }}
            />
          </button>
        </div>
      </Drawer>
    </>
  )
}
