import React from 'react'
import { Helmet } from 'react-helmet'
import ConnectedApp from './ConnectedApp'
import { AppSideComponent } from './AppSideComponent'
import { SocketConnection } from './SocketConnection'
import { isActualFuture9 } from './App'

export const MainApp = () => {
  const hostname = window.location.hostname
  const isClubDomain = hostname.endsWith('.club')

  return (
    <>
      <Helmet>
        {isActualFuture9() && !isClubDomain && (
          <meta name='robots' content='noindex, nofollow' />
        )}

        {isActualFuture9() && isClubDomain && (
          <link
            rel='canonical'
            href={`https://${hostname}${window.location.pathname}`}
          />
        )}
      </Helmet>

      <AppSideComponent />
      <SocketConnection />
      <ConnectedApp />
    </>
  )
}
