import { Container } from '@mui/material'
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import { getAssetUrl, getThemeAssetUrl } from '../../../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { makePostHttpCallAsync } from '../../../../api'
import {
  SignalWifi2Bar,
  Telegram,
  Twitter,
  WhatsApp,
  YouTube,
} from '@mui/icons-material'
import { BsGlobe } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { isFuture9 } from '../../../../App'

export const DEFAULT_COMPANY = [
  {
    name: 'About Us',
    link: '/about-us',
  },
  {
    name: 'Rules & Regulation',
    link: '/rules-and-regulation',
  },
  {
    name: 'Privacy Policy',
    link: '/privacy-policy',
  },
  {
    name: 'Sitemap',
    link: '',
  },
  {
    name: 'Responsible Gambling',
    link: '/responsible-gambling',
  },
  {
    name: 'Terms And Conditions',
    link: '/terms-and-condition',
  },
]

export const DEFAULT_RESOURCE = [
  {
    name: 'Blogs',
    link: '/blogs',
  },
  {
    name: 'Promotions',
    link: '/promotions',
  },
]

export const DEFAULT_FAQ = [
  {
    name: 'FAQ',
    link: '',
  },
  {
    name: 'Tutorials',
    link: '',
  },
  {
    name: 'Market',
    link: '',
  },
  {
    name: 'Loyalty',
    link: '',
  },
  {
    name: 'Contact Us',
    link: '',
  },
]

export const Footer = () => {
  const DEFAULT_GAME = [
    {
      name: 'Live Casino',
      link: '/live-casino',
    },
    {
      name: 'Crash Games',
      link: '/crash-games',
    },
    {
      name: 'Virtual Games',
      link: '/virtual-games',
    },
    {
      name: 'Slot Games',
      link: '/slot-games',
    },
    {
      name: 'Lottery Games',
      link: '/lottery',
    },
    // {
    //   name: 'Premium Sports Book',
    //   link: '/sports-book',
    // },
    {
      name: 'E-Sports',
      link: '',
    },
  ]

  const currentDomain = useSelector((state: any) => state.currentDomain)
  const dispatch = useDispatch()
  const newsLetterEmailSent = () => {
    let formData = new URLSearchParams()
    formData.append('email', email)
    formData.append('whiteLabelId', currentDomain.whitelabelId)
    if (email === '' || !email.includes('@') || !email.includes('.')) {
      toast.error(_getLocalString('LOC_INVALID_MAIL'))
    } else {
      makePostHttpCallAsync(
        dispatch,
        'api/common/newsletter',
        currentDomain.type,
        formData,
        false,
        false,
        'application/x-www-form-urlencoded',
      )
        .then((response: any) => {
          toast.success(response.message)
        })
        .catch(async (error: any) => {
          let err = await error
          toast.error(err?.message || err)
        })

      setEmail('')
    }
  }

  const navigate = useNavigate()

  const openPage = (link: string) => {
    navigate(link)
  }

  const { type } = useSelector((state: any) => state.currentDomain)
  const [email, setEmail] = useState('')
  const { socialMedia, logo, logoText, registration } = useSelector(
    (state: any) => state.appThemeConfiguration,
  )
  const footerData = useSelector((state: any) => state.appCommonTheme.footer)
  return (
    <div
      className='dark:bg-transparent bg-[#171717]'
      style={{
        backgroundImage:
          type === 'b2b' ? '' : `url(${getAssetUrl('footer/light_bg.webp')})`,
        borderTop: type === 'b2b' ? 'solid 1rem var(--theme-color)' : '',
      }}
    >
      <div className={`md:!pt-20 !pt-12`}>
        <Container maxWidth={'xl'} fixed>
          <div className='flex flex-col items-center justify-between gap-5 md:flex-row md:gap-0'>
            <div className='md:basis-1/2'>
              <div>
                <div className='md:w-9/12 w-[70%] !font-bold text-[22px] uppercase gradient_text font-bebas md:text-5xl md:tracking-wide md:leading-10'>
                  {footerData?.mainTitle}
                </div>
              </div>
              <div className='mt-2 text-sm text-white md:text-base'>
                In {logoText}, {footerData?.mainDescription}
              </div>
            </div>

            {currentDomain.type === 'b2c' &&
              currentDomain.domainName !== 'toyo247.ai' && (
                <div className='flex justify-end w-full md:basis-1/2 md:w-auto'>
                  <FormControl
                    variant='outlined'
                    className='md:!w-3/4 w-full !ml-auto '
                  >
                    <OutlinedInput
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ border: 'solid' }}
                      className='!rounded-xl  md:!px-6 !px-3  !bg-[var(--form-gradientFrom)] !text-white !border !border-[var(--form-gradientTo)] searchBox_input'
                      classes={{
                        input:
                          'md:!px-5 !px-2 md:!text-[18px] !text-[15px] md:placeholder:!text-[18px] placeholder:!text-[15px] md:!py-6 !py-3 !text-white placeholder:!text-white placeholder:!opacity-100  placeholder:font-primary !font-normal !font-primary ',
                      }}
                      id='outlined-adornment-weight'
                      startAdornment={
                        <InputAdornment position='start'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 22 20'
                            fill='none'
                            className='md:h-[20px] h-[14px] md:w-[22px] w-[16px]'
                          >
                            <path
                              d='M21.7764 4.12909L14.1236 11.7818C13.2703 12.633 12.1143 13.1109 10.9091 13.1109C9.70387 13.1109 8.54784 12.633 7.69455 11.7818L0.0418182 4.12909C0.0290909 4.27273 0 4.40273 0 4.54545V15.4545C0.00144351 16.6596 0.480802 17.8149 1.33293 18.6671C2.18505 19.5192 3.34037 19.9986 4.54545 20H17.2727C18.4778 19.9986 19.6331 19.5192 20.4853 18.6671C21.3374 17.8149 21.8167 16.6596 21.8182 15.4545V4.54545C21.8182 4.40273 21.7891 4.27273 21.7764 4.12909Z'
                              fill='white'
                            />
                            <path
                              d='M12.8382 10.4964L21.1418 2.19182C20.7396 1.52484 20.1722 0.972761 19.4945 0.588846C18.8168 0.20493 18.0516 0.00212788 17.2727 0H4.54545C3.76656 0.00212788 3.00136 0.20493 2.32366 0.588846C1.64596 0.972761 1.07862 1.52484 0.676364 2.19182L8.98 10.4964C9.49234 11.0066 10.186 11.2932 10.9091 11.2932C11.6322 11.2932 12.3258 11.0066 12.8382 10.4964Z'
                              fill='white'
                            />
                          </svg>
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position='end'>
                          <img
                            onClick={newsLetterEmailSent}
                            className='cursor-pointer md:w-[45px] w-[25px]'
                            src={getAssetUrl(
                              'footer/footer_search_box_icon.svg',
                            )}
                            alt='Footer Search Box'
                          />
                        </InputAdornment>
                      }
                      aria-describedby='outlined-weight-helper-text'
                      inputProps={{
                        'aria-label': 'weight',
                        placeholder: _getLocalString('LOC_ENTER_EMAIL'),
                      }}
                    />
                  </FormControl>
                </div>
              )}
          </div>
          <div className='grid grid-cols-2 gap-10 mt-10 mb-8 md:mt-20 md:grid-cols-6 md:gap-5'>
            <div className='flex flex-col col-span-2 gap-3 md:gap-7'>
              <div>
                <div className='font-serif md:text-5xl text-[25px] font-bold gradient_text max-w-fit '>
                  {logo ? (
                    <img alt='Theme URL' src={getThemeAssetUrl(logo)} />
                  ) : (
                    logoText
                  )}
                </div>
              </div>
              <div className='md:text-base text-[13px] md:leading-normal leading-tight text-white '>
                {logoText} {footerData?.companyDetail}
              </div>
              <div className='flex gap-5 text-white'>
                {socialMedia?.facebook && (
                  <a
                    href={socialMedia.facebook}
                    target='_blank'
                    aria-label='facebook'
                  >
                    <FacebookOutlinedIcon
                      style={{ color: '#1877F2' }}
                      className='hover:!text-pink-600 xs:!text-5xl md:!text-4xl !text-2xl hover:cursor-pointer'
                      fontSize='large'
                    />
                  </a>
                )}
                {socialMedia?.twitter && (
                  <a
                    href={socialMedia.twitter}
                    target='_blank'
                    aria-label='twitter'
                  >
                    <img
                      src={getAssetUrl('icons/twitter.webp')}
                      style={{ color: '#1DA1F2', width: '2.5rem' }}
                      className=' hover:cursor-pointer'
                    />
                  </a>
                )}
                {socialMedia?.instagram && (
                  <a
                    href={socialMedia.instagram}
                    target='_blank'
                    aria-label='instagram'
                  >
                    <InstagramIcon
                      style={{ color: '#fe1e7f' }}
                      className='hover:!text-pink-600 xs:!text-5xl md:!text-4xl !text-2xl  hover:cursor-pointer'
                      fontSize='large'
                    />
                  </a>
                )}
                {socialMedia?.telegram && (
                  <a
                    href={socialMedia.telegram}
                    target='_blank'
                    aria-label='telegram'
                  >
                    <Telegram
                      style={{ color: '#0088cc' }}
                      className='hover:!text-pink-600 xs:!text-5xl md:!text-4xl !text-2xl  hover:cursor-pointer'
                      fontSize='large'
                    />
                  </a>
                )}
                {socialMedia?.youtube && (
                  <a
                    href={socialMedia.youtube}
                    target='_blank'
                    aria-label='youtube'
                  >
                    <YouTube
                      style={{ color: 'red' }}
                      className='hover:!text-pink-600 xs:!text-5xl md:!text-4xl !text-2xl  hover:cursor-pointer'
                      fontSize='large'
                    />
                  </a>
                )}
                {socialMedia?.website && (
                  <a
                    href={socialMedia.website}
                    target='_blank'
                    aria-label='website'
                  >
                    <BsGlobe
                      className='hover:!text-pink-600 xs:!text-5xl md:!text-4xl !text-2xl  hover:cursor-pointer'
                      fontSize='large'
                    />
                  </a>
                )}
                {socialMedia?.signal && (
                  <a
                    href={socialMedia.signal}
                    target='_blank'
                    aria-label='signal'
                  >
                    <SignalWifi2Bar
                      className='hover:!text-pink-600 xs:!text-5xl md:!text-4xl !text-2xl  hover:cursor-pointer'
                      fontSize='large'
                    />
                  </a>
                )}
                {registration?.whatsappNumber && (
                  <a
                    href={`https://wa.me/${registration.whatsappNumber}`}
                    aria-label='whatsapp'
                    target='_blank'
                  >
                    <WhatsApp
                      style={{ color: '#25D366' }}
                      className='hover:!text-pink-600 xs:!text-5xl md:!text-4xl !text-2xl  hover:cursor-pointer'
                      fontSize='large'
                    />
                  </a>
                )}
              </div>
            </div>
            <div>
              <div className='mb-3 text-lg font-bold tracking-wide text-white md:mb-5 md:text-2xl font-bebas'>
                {_getLocalString('LOC_GAME')}
              </div>
              <ul className='text-sm leading-9 list-disc list-inside md:text-base'>
                {[
                  currentDomain.type === 'b2c' && isFuture9()
                    ? {
                        name: 'Online Sport Exchange',
                        link: '/online-exchange',
                      }
                    : {
                        name: 'Exchange',
                        link: '/',
                      },
                  ...DEFAULT_GAME,
                ].map((game, index) => (
                  <li
                    key={index}
                    className='cursor-pointer'
                    onClick={() => openPage(game.link)}
                  >
                    {' '}
                    {game.name}{' '}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className='mb-3 text-lg font-bold tracking-wide text-white md:mb-5 md:text-2xl font-bebas'>
                {_getLocalString('LOC_COMPANY')}
              </div>
              <ul className='text-sm leading-9 list-disc list-inside md:text-base'>
                {DEFAULT_COMPANY.map((company, index) => (
                  <li
                    onClick={() =>
                      currentDomain.type === 'b2c' && openPage(company.link)
                    }
                    key={index}
                    className='cursor-pointer'
                    style={{
                      lineHeight: '2rem',
                      textAlign: 'start',
                    }}
                  >
                    {company.name}
                  </li>
                ))}
              </ul>
            </div>

            {currentDomain.type === 'b2c' && (
              <div>
                <div className='mb-3 text-lg font-bold tracking-wide text-white md:mb-5 md:text-2xl font-bebas'>
                  {_getLocalString('LOC_RESOURCES')}
                </div>
                <ul className='text-sm leading-9 list-disc list-inside md:text-base'>
                  {DEFAULT_RESOURCE.map((resource, index) => (
                    <li
                      onClick={() => openPage(resource.link)}
                      key={index}
                      className='cursor-pointer'
                      style={{
                        lineHeight: '2rem',
                        textAlign: 'start',
                      }}
                    >
                      {resource.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {currentDomain.type === 'b2c' && (
              <div>
                <div className='text-2xl font-bold tracking-wide text-white font-bebas mt-14'></div>
                <ul
                  className='text-sm leading-9 list-disc list-inside md:text-base'
                  style={{ textAlign: 'center' }}
                >
                  {DEFAULT_FAQ.map((faq, index) => (
                    <li
                      onClick={() => openPage(faq.link)}
                      key={index}
                      className='cursor-pointer'
                      style={{
                        lineHeight: '1.5rem',
                        textAlign: 'start',
                      }}
                    >
                      {faq.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Container>
        <div className='!text-[#AFAFAF] md:!text-base text-sm md:py-7 py-3 border-y dark:border-[#E992002B] border-[#EEEEEE1A] !font-questrial'>
          <Container
            maxWidth={'xl'}
            fixed
            className='!font-questrial md:leading-normal leading-tight '
          >
            In {logoText}, {footerData?.companyTermsCondition}
          </Container>
        </div>
        <div className='text-[#AFAFAF] md:py-7 py-4 md:text-base text-sm text-center font-questrial'>
          {footerData?.copyrighMessage} {logoText}
        </div>
      </div>
    </div>
  )
}
